import { BackNextButton } from 'app/components/BackNextButton';
import React from 'react';
import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import {
  ApiError,
  GeneralLookup,
  LookupService,
  RegisterRider,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';

type Props = {
  tryItRegistration: RegisterRider;
  setTryItRegistration: React.Dispatch<React.SetStateAction<RegisterRider>>;
  onClickPlacementTwoBack?: () => void;
  handlePlacementSubmit?: () => void;
};

const NewMemberIndividualPlacementFormTwoNewRider = ({
  tryItRegistration,
  setTryItRegistration,
  onClickPlacementTwoBack,
  handlePlacementSubmit,
}: Props) => {
  const [associationLookUp, setAssociationLookUp] =
    React.useState<GeneralLookup[]>();
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();
  const { lookupGetAllLookups } = LookupService;
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

  function getAllLookupData(names?: string) {
    handleRequest(lookupGetAllLookups(names))
      .then(data => {
        if (data) {
          data?.map(item => setAssociationLookUp(item?.values!));
        }
      })
      .catch(() => setError(apiError));
  }
  React.useEffect(() => {
    getAllLookupData('ASSOCIATIONS');
  }, []);
  const handleFieldChange = (index, field, value) => {
    setTryItRegistration?.(prevData => {
      const updatedRiderAssociations = [...prevData?.riderAssociations!];
      const associationData = associationLookUp?.[index];
      updatedRiderAssociations[index] = {
        ...updatedRiderAssociations[index],
        [field]: value,
        association: {
          id: associationData?.id ?? '',
          name: associationData?.name ?? '',
          code: associationData?.code,
          children: associationData?.children,
          otherName: associationData?.otherName,
          isAdminLive: associationData?.isAdminLive,
        },
      };
      return { ...prevData, riderAssociations: updatedRiderAssociations };
    });
  };
  const prevRiderAssociationsRef = React.useRef(
    tryItRegistration?.riderAssociations,
  );

  React.useEffect(() => {
    if (!tryItRegistration?.riderAssociations) {
      return;
    }

    const areAllMappingsValid = tryItRegistration.riderAssociations.every(
      value =>
        (value?.name && value?.idNumber) || (!value?.name && !value?.idNumber),
    );
    setIsButtonDisabled(!areAllMappingsValid);

    // Check if riderAssociations have changed
    if (
      prevRiderAssociationsRef.current !== tryItRegistration.riderAssociations
    ) {
      prevRiderAssociationsRef.current = tryItRegistration.riderAssociations;

      const filteredAssociations = tryItRegistration.riderAssociations.filter(
        obj => obj.name !== '' && obj.idNumber !== '',
      );

      // Check if filteredAssociations have changed
      if (prevRiderAssociationsRef.current !== filteredAssociations) {
        prevRiderAssociationsRef.current = filteredAssociations;

        setTryItRegistration(prevData => {
          if (prevData.riderAssociations !== filteredAssociations) {
            return {
              ...prevData,
              riderAssociations: filteredAssociations,
            };
          }
          return prevData;
        });
      }
    }
  }, [
    tryItRegistration?.riderAssociations,
    setIsButtonDisabled,
    setTryItRegistration,
  ]);
  return (
    <MainWrapper className="form-MainWrapper">
      <OverflowWrapper className="OverflowWrapper">
        <p className="over-flow-header para-one">
          TO DOCUMENT PRIOR COMPETITION RECORDS:
        </p>
        <p className="over-flow-header para-two">
          <span>
            LIST YOUR NAME EXACTLY AS IT APPEARS IN ASSOCIATION RECORDS, AND{' '}
          </span>
          list your ID# for each association in which you have competed. (Not
          one in which you are simply a card holding member who has never
          participated in competition).
        </p>
        <MappedDataWrapper className="MappedDataWrapper">
          {associationLookUp?.map((value, index) => (
            <TextFieldGrid className="TextFieldGrid" key={index}>
              <p className="para-three">{value?.name}</p>
              <StyledNameField
                value={tryItRegistration?.riderAssociations?.[index]?.name}
                onChange={e => handleFieldChange(index, 'name', e.target.value)}
                name={`name${index + 1}`}
                placeholder="name"
              />

              <StyledIdField
                value={tryItRegistration?.riderAssociations?.[index]?.idNumber}
                onChange={e =>
                  handleFieldChange(index, 'idNumber', e.target.value)
                }
                name={`id${index + 1}`}
                placeholder="ID number"
              />
            </TextFieldGrid>
          ))}
        </MappedDataWrapper>

        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={() => handlePlacementSubmit?.()}
            onClickBack={onClickPlacementTwoBack}
            next="Pay"
            back="Back"
            disableNext={isButtonDisabled}
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  position: relative;
  height: calc(100vh - 230px);
  width: 100%;
  .para-one {
    color: #ff9900;
    font-size: 13px;
  }
  .para-two {
    color: #a7a7a7;
    font-size: 13px;
  }
  span {
    text-transform: uppercase;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .para-one {
      font-size: 11px;
    }
    .para-two {
      font-size: 11px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .para-one {
      font-size: 12px;
    }
    .para-two {
      font-size: 12px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .para-one {
      font-size: 13px;
    }
    .para-two {
      font-size: 13px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const MappedDataWrapper = styled.div`
  height: calc(100vh - 225px);
  overflow: auto;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding-bottom: 20px;
  .para-three {
    flex: 0 0 calc(33% - 20px);
    font-size: 11px;
    color: #9597a6;
  }
  label {
    display: none !important;
  }
`;
const StyledNameField = styled(TextField)`
  display: flex;
  align-items: center;
  margin: 0px;
  flex: 0 0 calc(33% - 20px);
  input {
    width: 100%;
    height: 30px;
  }
`;
const StyledIdField = styled(TextField)`
  display: flex;
  align-items: center;
  flex: 0 0 calc(33% - 20px);
  margin: 0px;
  input {
    width: 100%;
    height: 30px;
  }
  ::placeholder {
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -15px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    backdrop-filter: blur(5px);
  }
  @media (min-width: 100px) and (max-width: 575px) {
    &.bg-color-change {
      bottom: -2px;
    }
  }
  @media (min-width: 576px) and (max-width: 944px) {
    height: 45px;
    &.bg-color-change {
      bottom: -3px;
    }
  }
  @media (min-width: 945px) and (max-width: 1199px) {
    height: 50px;
    &.bg-color-change {
      bottom: -6px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
    &.bg-color-change {
      bottom: -6px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
    &.bg-color-change {
      bottom: -27px;
    }
  }
`;

export default NewMemberIndividualPlacementFormTwoNewRider;
