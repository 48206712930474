import AdminCustomButton from 'app/components/AdminCustomButton';
import AdminDropDown from 'app/components/AdminDropDown';
import {
  DeleteIcon,
  DetailArrowIcon,
  EditIcon,
  FilterIcon,
  LeftArrowIcon,
  ThreeDotIcon,
} from 'app/assets/icons';
import { AdminProfileInfo } from 'app/components/AdminProfileInfo';
import { AdminSearch } from 'app/components/AdminSearchField';
import { CustomDeletePopup } from 'app/components/CustomDeletePopup';
import { AdminTab } from 'app/components/AdminTab';
import TeamProfileTable from 'app/components/CustomProfileTable';
import DropdownComponent from 'app/components/CustomFilterPopup';
import { BlurredLabel } from 'app/components/SHLabel';
import { AdminTextField } from 'app/components/AdminTextField';
import BackButton from 'app/components/BackButton';
import StatusChip from 'app/components/StatusChip';
import React, { useEffect, useState } from 'react';
import { GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import styled from 'styled-components';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import AdminPhoneInput from 'app/components/AdminPhoneInput';
import { messages } from '@progress/kendo-react-inputs/dist/npm/messages';
import { Button } from '@progress/kendo-react-buttons';
import { useErrorHandlingSlice } from 'app/error/slice';
import { SHToolTip } from 'app/components/SHToolTip';
import { MainLabelSemiBold, SubLabelSemiBold } from 'app/components/SHLabel';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import { AdminTextArea } from 'app/components/AdminTextArea';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import { CardSkeletonLoader } from 'app/components/CardSkeletonLoader';
import { FormSkeletonLoader } from 'app/components/FormSkeletonLoader';
import { CameraIcon } from 'app/assets/icons';
import Loader from 'app/components/Loader';
import { CommonLoader } from 'app/components/CommonLoader';
import { AdminCustomPopUp } from 'app/components/AdminCustomPopUp';
import AddTeamCouch from '../AddCoachPopup';
import { useDispatch } from 'react-redux';
import AdminCustomYear from 'app/components/AdminCustomYear';
import AdminMultiSelectDropDown from 'admin/components/AdminMultiSelectDropdown';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { getLookupArray } from 'utils/common';
import AdminCustomYearPicker from 'app/components/AdminCustomYear';
import { RiderFilteredData } from 'admin/components/AdminMultiSelectDropdown/FilteredList';
import AdminShowRiderCard from 'app/components/AdminShowRiderCard';

type Props = {};

const data = ['All', 'Active', 'Inactive'];

const handleItemClick = (item: string) => {};

export const tabNamesArray = [
  { tab: 'Team Profile', id: 1 },
  { tab: 'Coaches', id: 2 },
  // { tab: 'Payment Details', id: 3 }, for future use
  { tab: 'Rider', id: 4 },
  // { tab: 'Important', id: 5 }, informed by PM to comment this section
];
export const Components = (props: Props) => {
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();

  const trailData = [
    { id: '1', name: 'Option 1' },
    { id: '2', name: 'Option 2' },
    { id: '3', name: 'Option 3' },
    { id: '4', name: 'Option 4' },
    { id: '5', name: 'Option 5' },
  ];
  const breadCrumpitems = [
    {
      id: '',
      text: 'Showyeda admin',
      disabled: true,
    },
    {
      id: '/components',
      text: 'Teams',
    },
    {
      id: '',
      text: 'Team Profile',
      disabled: true,
    },
  ];
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const [phoneField, setPhoneField] = useState<string>('56325156');
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const [dropDownData, setDropDownData] = useState<GeneralLookup>();
  const [imgSrc, setImgSrc] = useState<string | null>('');
  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };

  const onDeleteClick = () => {
    setShowDeletePopup(true);
  };
  const handleClick = () => {
    dispatch(
      actions.dispatchToast({
        errorTxt: 'Success Toast',
        show: true,
        errorType: 'success',
      }),
    );
  };
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [inputValue, setInputValue] = useState('');
  const [active, setActive] = useState(true);
  const [inactive, setInActive] = useState(true);
  const handleChange = e => setInputValue(e.target.value);
  const statusChips = [
    { StatusType: 'active', text: 'Active' },
    { StatusType: 'inactive-team', text: 'Inactive' },
    { StatusType: 'inactive-team-profile', text: 'Inactive' },
    { StatusType: 'active-paid', text: 'Active - Paid' },
    { StatusType: 'active-not-paid', text: 'Active - Not Paid' },
    { StatusType: 'not-assigned', text: 'Not Assigned' },
  ];
  const [visible, setVisible] = useState(false);
  const handleAddNew = () => {
    setVisible(true);
  };
  const handleClose = () => {
    setVisible(false);
  };
  const handleDeleteClick = () => {};
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const [lookupData, setLookupData] = useState('');
  const [selectedIds, setSelectedIds] = useState('');
  useEffect(() => {
    if (lookupData) {
      getAllLookupData(lookupData);
    }
  }, [lookupData]);
  return (
    <Wrapper>
      <PageWrapper>
        <AdminProfileInfo
          src="https://via.placeholder.com/300x200"
          name="Abdul Rahuman"
          subLabel="Sub Label Test"
        />
        <ProfileImageUploadCard
          setImgSrc={setImgSrc}
          imgSrc={imgSrc}
          buttonLabel="Edit"
          buttonType="secondary"
          svgIcon={<CameraIcon width="15px" height="15px" />}
        >
          <h4 className="header-title">Abernathy Farm</h4>
          <p className="para">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.{' '}
          </p>
        </ProfileImageUploadCard>
        <ProfileImageUploadCard
          setImgSrc={setImgSrc}
          imgSrc={imgSrc}
          buttonLabel="Upload Image"
          buttonType="primary"
        />
        <AdminSearch
          placeholder="Search for a rider"
          onSearchTextEntering={handleSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />

        <AdminPhoneInput
          disabled={false}
          number={phoneField}
          setNumber={setPhoneField}
        ></AdminPhoneInput>

        {/* Buttons Start Here */}
        <ButtonWrapper>
          <BlurredLabel>Popular filter</BlurredLabel>
          <AdminCustomButton
            buttonType="primary"
            isActive={false}
            className={active ? 'active-inactive' : 'Inactive-button'}
            onClick={() => {
              setActive(!active);
            }}
          >
            Active
          </AdminCustomButton>
          <AdminCustomButton
            buttonType="primary"
            isActive={false}
            className={inactive ? 'active-inactive' : 'Inactive-button'}
            onClick={() => {
              setInActive(!inactive);
            }}
          >
            Inactive
          </AdminCustomButton>
          <DropdownComponent
            data={data}
            onItemClick={handleItemClick}
            label="More filters"
            svgIcon={<FilterIcon />}
          />
        </ButtonWrapper>
        {/* Buttons End Here */}
        <AdminDropDown
          className="drop-down"
          data={trailData}
          validator={simpleValidator.current}
          validations="required"
          onDataChange={data => {
            setDropDownData(data);
          }}
          defaultValue={dropDownData}
          label="Label"
        ></AdminDropDown>
        <br />
        <CustomBreadCrumb preData={breadCrumpitems} />
        {/* Icons Start Here */}
        <IconWrapper>
          <DeleteIcon />
          <EditIcon />
          <DetailArrowIcon />
          <ThreeDotIcon />
          <FilterIcon />
          <LeftArrowIcon />
        </IconWrapper>
        {/* Icons End Here */}
        <br />
        <TeamProfileTable></TeamProfileTable>
        {/* deletePopup */}
        <button onClick={onDeleteClick}>Delete</button>
        <StyledDeletePopup
          deleteIcon={true}
          headerUnderline={false}
          popupSubLabel="Are you sure you want to delete ?"
          className="body-scroll-lock height-full delete-update"
          popupLabel="Delete this Request"
          setVisible={setShowDeletePopup}
          visible={showDeletePopup}
          align="centre"
          btnLabel1="Cancel"
          btnLabel2="Delete"
          handlebtn2Click={() => {
            return;
          }}
          handlebtn1Click={() => {
            setShowDeletePopup(false);
          }}
        ></StyledDeletePopup>
        <TextFieldWrapper>
          <AdminTextField
            label="Text Name"
            value={inputValue}
            placeholder="Text name"
            onChange={handleChange}
            isLoader={inputValue ? false : true}
            validations="required"
            validator={simpleValidator.current}
            name="text name"
          />
        </TextFieldWrapper>
        <br />
        <BackButton />
        {/* Tab components */}
        <AdminTab data={tabNamesArray} />
        <StatusTag>
          {statusChips.map((chip, index) => (
            <StatusChip
              key={index}
              className=""
              StatusType={chip.StatusType}
              text={chip.text}
            />
          ))}
        </StatusTag>

        <AdminPhoneInput
          setNumber={setPhoneNumber}
          number={phoneNumber}
          disabled={false}
        />
        {/* Toast Button Starts Here */}
        <br />
        <Button onClick={handleClick}>Toast Button</Button>
        {/* Toast Button Ends Here */}

        <SHToolTip position="left" className=" toottip">
          <div style={{ width: 'fit-content' }}>
            <button title="delete" onClick={handleDeleteClick}>
              {' '}
              Delete <DeleteIcon />
            </button>
          </div>
        </SHToolTip>

        <MainLabelSemiBold> Teams </MainLabelSemiBold>
        <SubLabelSemiBold>Abernathy Farm</SubLabelSemiBold>
        <AdminTextArea
          label="Text Area"
          validations="required"
          validator={simpleValidator.current}
          disabled={false}
        />
        <SubLabelSemiBold>Abernathy Farm</SubLabelSemiBold>
        <AdminCustomButton buttonType="primary" onClick={handleAddNew}>
          Add New
        </AdminCustomButton>
        <AdminCustomPopUp
          visible={visible}
          setVisible={setVisible}
          popupLabel={'Add New Coach'}
          closeToggle={handleClose}
          defaultPopupFooter={true}
          children={<AddTeamCouch />}
        />

        {/* Table Skeleton Loader starts here */}
        <TableSkeletonLoader
          rowContent={6}
          isIndividualLoader={true}
          width={120}
          height={17}
        />
        {/* Table Skeleton Loader ends here */}
        <br />
        {/* Form Skeleton Loader starts here */}
        <FormSkeletonLoader isLabel />
        {/* Form Skeleton Loader ends here */}
        <br />
        {/* Card Skeleton Loader starts here */}
        <CardSkeletonLoader
          isProfileImageLoader
          isEditImageLoader
          isHeaderLoader
          isTextLoader
        />
        <br />
        <CardSkeletonLoader
          isProfileImageLoader
          isHeaderLoader
          isTextLoader
          lineContent={1}
          headerWidth={100}
          textWidth={200}
        />
        {/* Card Skeleton Loader ends here */}
        <AdminCustomYearPicker
          hideStar={true}
          validations="required"
          validator={simpleValidator.current}
          // disabled={true}
          label="Pick a year"
          isLoader
        />
        <AdminShowRiderCard
          title="Elementary Pearl"
          totalTryitTeams={0}
          totalScratch={0}
          totalRiders={0}
          totalRides={0}
          totalTryitRides={0}
        />
      </PageWrapper>
      {/* <CommonLoader loaderType="main" circleSize="50" thickness="8" /> */}
    </Wrapper>
  );
};

const StyledDeletePopup = styled(CustomDeletePopup)`
  .k-window-actions {
    margin: unset;
  }
  .k-window-titlebar {
    border: none;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow: auto;
  background: #ffffff;
  .header-title {
    color: rgba(0, 0, 0, 1);
    margin: 0px;
    font-weight: 600;
    font-size: 22px;
  }
  .para {
    color: rgba(146, 146, 146, 1);
    margin: 0px;
    font-size: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;
const PageWrapper = styled.div`
  padding: 30px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px 10px;
`;
const IconWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 10px;
`;
const TextFieldWrapper = styled.div`
  padding-top: 10px;
`;
const StatusTag = styled.div`
  display: inline-block;
  padding-top: 10px;
`;
