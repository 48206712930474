import { GridLayoutItem } from '@progress/kendo-react-layout';
import { CustomgridLayoutWrapper } from 'admin/components/wrappers';
import AdminDropDown from 'app/components/AdminDropDown';
import { AdminTextField } from 'app/components/AdminTextField';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import styled from 'styled-components';
import { useEffect } from 'react';
import { BlurredLabel, LabelMedium } from 'app/components/SHLabel';
import { MappedCheckboxGrid } from 'app/components/mappedCheckboxGrid';
import { AdminCheckBox } from 'app/components/AdminCheckbox';
import { Coach, GeneralLookup, GeneralLookupWrapper } from 'services/openapi';
import { getLookupArray, seasonValue } from 'utils/common';
import { DropDownModel } from 'app/components/SHdropdown';
import { AdminDragAndDropFiles } from 'app/components/AdminDragAndDropFiles';
import React from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { defaultLookup } from 'admin/pages/RiderIndividual/components/RiderCreatePage/staticDatas';
import { booleanLookupData, selectedObject } from '../../staticDatas';
import { FormSkeletonLoader } from 'app/components/FormSkeletonLoader';
type Props = {
  action: string | undefined;
  simpleValidator: React.MutableRefObject<SimpleReactValidator>;
  coachPayload: Coach;
  setCoachPayload: React.Dispatch<React.SetStateAction<Coach>>;
  isAfiliationSelected: boolean;
  setImgSrc: React.Dispatch<React.SetStateAction<string | null>>;
  imgSrc: string | null;
  setImageFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
  setImageFileInsurence: React.Dispatch<React.SetStateAction<Blob | undefined>>;
  imgSrcInsurance: string | null;
  imageFileInsurence: Blob | undefined;
  setImgSrcInsurance: React.Dispatch<React.SetStateAction<string | null>>;
  setShowInsuranceFileError: React.Dispatch<React.SetStateAction<boolean>>;
  showInsuranceFileError: boolean;
  setIsAfiliationSelected: React.Dispatch<React.SetStateAction<boolean>>;
  coachDataLoading: boolean;
  lookUps: GeneralLookupWrapper[];
  getAllLookupData: (names?: string | undefined) => void;
};

export const CoachInfoFormBlock = ({
  action,
  simpleValidator,
  coachPayload,
  setCoachPayload,
  isAfiliationSelected,
  setImgSrc,
  imgSrc,
  setImageFile,
  setImageFileInsurence,
  imageFileInsurence,
  imgSrcInsurance,
  setImgSrcInsurance,
  setShowInsuranceFileError,
  showInsuranceFileError,
  setIsAfiliationSelected,
  coachDataLoading,
  lookUps,
  getAllLookupData,
}: Props) => {
  const [isFileUploaded, setIsFileUploaded] = React.useState(false);

  const handleChange = event => {
    const { name, value } = event.target;
    setCoachPayload(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDropdownChange = (data: GeneralLookup, name: string) => {
    setCoachPayload(prevData => ({
      ...prevData,
      [name]: data,
    }));
  };
  const handleBooleanDropdownChange = (
    genericData: DropDownModel,
    name: string,
  ) => {
    setCoachPayload(prevData => ({
      ...prevData,
      [name]: genericData.id === 0 ? true : false,
    }));
  };
  const handleFileOnCancelClick = () => {
    setIsFileUploaded(false);
    setImageFileInsurence(undefined);
    setImgSrcInsurance(null);
  };
  const handleCheckboxOptionChange = (data, isChecked: boolean) => {
    setIsAfiliationSelected(false);
    let filteredEmptyObjArray;
    if (action === 'create') {
      filteredEmptyObjArray = coachPayload.affiliations?.filter(
        obj => obj.id !== '',
      );
    } else if (action === 'update') {
      filteredEmptyObjArray = coachPayload.affiliations;
    }
    const defaultaffiliations = filteredEmptyObjArray;
    if (isChecked) {
      defaultaffiliations?.push(data);
      setCoachPayload?.({
        ...coachPayload,
        affiliations: defaultaffiliations,
      });
    } else {
      const filteredArray = defaultaffiliations?.filter(
        obj => obj.id !== data?.id,
      );
      setCoachPayload?.({
        ...coachPayload,
        affiliations:
          filteredArray?.length === 0 ? [defaultLookup] : filteredArray,
      });
    }
  };

  const handleImageSelect = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageFile(file);
    }
  };
  const handleImageSelectInsurance = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageFileInsurence(file);
      setImgSrcInsurance(null);
      setShowInsuranceFileError(false);
    }
  };

  useEffect(() => {
    getAllLookupData(
      'STATE,SEASON,ACTIVESTATUS,PROFESSIONALAFFILIATIONS,ACTIVETEAM',
    );
  }, []);

  React.useEffect(() => {
    if (action === 'create') {
      setCoachPayload(prevData => ({
        ...prevData,
        season: seasonValue(lookUps),
      }));
    }
  }, [lookUps]);

  return (
    <>
      <GridWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[
            { width: 'calc(32.33% - 14px)' },
            { width: 'calc(68.67% - 14px)' },
          ]}
          gap={{ rows: 20, cols: 30 }}
          className="Profilecard-grid"
        >
          <GridLayoutItem>
            <ProfileImageUploadCard
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              onSelectFile={handleImageSelect}
              uploadButtonLabel={`${imgSrc ? 'Change Image' : 'Upload Image'}`}
              uploadButtonType="primary"
              className="profilecard"
              isLoader={coachDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <CustomgridLayoutWrapper
              rows={[{ height: 'auto' }]}
              cols={[
                { width: 'calc(50% - 11px)' },
                { width: 'calc(50% - 11px)' },
              ]}
              gap={{ rows: 20, cols: 10 }}
              className="common-style"
            >
              <GridLayoutItem>
                <AdminDropDown
                  label="Season "
                  placeholder=""
                  onDataChange={data => handleDropdownChange(data, 'season')}
                  defaultValue={
                    action === 'update'
                      ? coachPayload.season
                      : seasonValue(lookUps)
                  }
                  data={getLookupArray('SEASON', lookUps)}
                  validations="required|max:100"
                  validator={simpleValidator.current}
                  className="field-style"
                  disabled
                  isLoader={coachDataLoading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="Coach First Name"
                  placeholder=""
                  value={coachPayload.firstName!}
                  onChange={handleChange}
                  name="firstName"
                  validations="required|max:100"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={coachDataLoading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="Coach Last Name"
                  placeholder=""
                  value={coachPayload.lastName!}
                  onChange={handleChange}
                  name="lastName"
                  validations="required|max:100"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={coachDataLoading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <CustomgridLayoutWrapper
                  rows={[{ height: 'auto' }]}
                  cols={[
                    { width: 'calc(50% - 5px)' },
                    { width: 'calc(50% - 5px)' },
                  ]}
                  gap={{ rows: 20, cols: 10 }}
                  className="grid-layout-sub"
                >
                  <GridLayoutItem>
                    {coachDataLoading ? (
                      <FormSkeletonLoader lineContent={1} textWidth={100} />
                    ) : (
                      <div className="checkbox">
                        <AdminCheckBox
                          value={coachPayload.isAssistant}
                          onChange={e => {
                            setCoachPayload({
                              ...coachPayload,
                              isAssistant: e.value,
                            });
                          }}
                          label="Assistant Coach ?"
                        />
                      </div>
                    )}
                  </GridLayoutItem>
                  <GridLayoutItem>
                    {coachDataLoading ? (
                      <FormSkeletonLoader lineContent={1} textWidth={100} />
                    ) : (
                      <div className="checkbox">
                        <AdminCheckBox
                          value={coachPayload.isTrial}
                          onChange={e => {
                            setCoachPayload({
                              ...coachPayload,
                              isTrial: e.value,
                            });
                          }}
                          label="Try-It ?"
                        />
                      </div>
                    )}
                  </GridLayoutItem>
                </CustomgridLayoutWrapper>
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="Email Address"
                  placeholder=""
                  value={coachPayload.email!}
                  onChange={handleChange}
                  name="email"
                  validations="required|max:100"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={coachDataLoading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="Phone Number"
                  placeholder=""
                  value={coachPayload.primaryPhone!}
                  onChange={e => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
                      setCoachPayload?.({
                        ...coachPayload,
                        primaryPhone: inputValue,
                      });
                    }
                  }}
                  name="primaryPhone"
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={coachDataLoading}
                />
              </GridLayoutItem>
            </CustomgridLayoutWrapper>
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[
            { width: 'calc(33.33% - 6px)' },
            { width: 'calc(16.61% - 8px)' },
            { width: 'calc(16.61% - 8px)' },
            { width: 'calc(33.33% - 6px)' },
          ]}
          gap={{ rows: 20, cols: 10 }}
          className="grid-layout-sub common-style second-grid"
          style={{ paddingTop: '30px' }}
        >
          <GridLayoutItem>
            <AdminTextField
              label="City"
              placeholder=""
              value={coachPayload.city!}
              onChange={handleChange}
              name="city"
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={coachDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              label="State"
              placeholder=""
              onDataChange={data => handleDropdownChange(data, 'state')}
              data={getLookupArray('STATE', lookUps)}
              defaultValue={coachPayload.state}
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={coachDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Zip"
              placeholder=""
              value={coachPayload.zip!}
              onChange={handleChange}
              name="zip"
              validations="required|max:15"
              validator={simpleValidator.current!}
              className="field-style"
              isLoader={coachDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem className="team-style">
            <AdminDropDown
              label="Coach Team "
              placeholder=""
              onDataChange={data => handleDropdownChange(data, 'team')}
              data={getLookupArray('ACTIVETEAM', lookUps)}
              defaultValue={coachPayload.team}
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={coachDataLoading}
            />
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
        <CommonWrapper>
          {!coachDataLoading ? (
            <BlurredLabel color="#475467">Coach Extras</BlurredLabel>
          ) : (
            <FormSkeletonLoader
              lineContent={1}
              textHeight={20}
              textWidth={100}
            />
          )}
          <CustomgridLayoutWrapper
            rows={[{ height: 'auto' }]}
            cols={[
              { width: 'calc(33.33% - 8px)' },
              { width: 'calc(33.33% - 6px)' },
              { width: 'calc(33.33% - 6px)' },
            ]}
            gap={{ rows: 20, cols: 10 }}
            style={{ paddingTop: '20px' }}
            className="common-style"
          >
            <GridLayoutItem>
              <AdminDropDown
                label="Status "
                placeholder=""
                onDataChange={data => handleDropdownChange(data, 'status')}
                defaultValue={coachPayload.status}
                data={getLookupArray('ACTIVESTATUS', lookUps)}
                validations="required|max:100"
                validator={simpleValidator.current}
                className="field-style"
                isLoader={coachDataLoading}
              />
            </GridLayoutItem>
            <GridLayoutItem>
              <AdminTextField
                label="Emergency Contact Name"
                placeholder=""
                value={coachPayload.emergencyContactName!}
                onChange={handleChange}
                name="emergencyContactName"
                className="field-style"
                hideStar={true}
                isLoader={coachDataLoading}
              />
            </GridLayoutItem>
            <GridLayoutItem>
              <AdminTextField
                label="Emergency Contact Phone"
                placeholder=""
                value={coachPayload.emergencyContactPhone!}
                onChange={e => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
                    setCoachPayload?.({
                      ...coachPayload,
                      emergencyContactPhone: inputValue,
                    });
                  }
                }}
                name="emergencyContactPhone"
                className="field-style"
                hideStar={true}
                isLoader={coachDataLoading}
              />
            </GridLayoutItem>
          </CustomgridLayoutWrapper>
        </CommonWrapper>
        <CommonWrapper>
          {!coachDataLoading ? (
            <LabelMedium color="#A3A3A3" className="afiliation-label">
              Affiliations
              <span className="red-star"> *</span>
              {isAfiliationSelected && (
                <span className="error-msg"> Select any affiliation</span>
              )}
            </LabelMedium>
          ) : (
            <FormSkeletonLoader
              lineContent={1}
              textHeight={20}
              textWidth={100}
            />
          )}
          <MappedCheckboxGrid
            onCheckboxOptionChange={handleCheckboxOptionChange}
            data={getLookupArray('PROFESSIONALAFFILIATIONS', lookUps)}
            valueArray={coachPayload.affiliations}
            isLoader={coachDataLoading}
          />
        </CommonWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[
            { width: 'calc(33.33% - 8px)' },
            { width: 'calc(33.33% - 6px)' },
            { width: 'calc(33.33% - 6px)' },
          ]}
          gap={{ rows: 20, cols: 10 }}
          style={{ paddingTop: '30px' }}
          className="common-style"
        >
          <GridLayoutItem>
            <AdminDropDown
              hideSearch={true}
              label="Insurance "
              placeholder=""
              genericData={booleanLookupData}
              onGeneralDataChange={genericData => {
                if (genericData.id === 1) {
                  setShowInsuranceFileError(false);
                  setImageFileInsurence(undefined);
                  setImgSrcInsurance(null);
                }
                handleBooleanDropdownChange(
                  genericData,
                  'hasVerifiedCertificateOfInsurance',
                );
              }}
              genericDefaultValue={selectedObject(
                coachPayload.hasVerifiedCertificateOfInsurance,
              )}
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={coachDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              hideSearch={true}
              label="Referral Program "
              placeholder=""
              genericData={booleanLookupData}
              onGeneralDataChange={genericData => {
                handleBooleanDropdownChange(
                  genericData,
                  'isInterestedInReferralProgram',
                );
              }}
              genericDefaultValue={selectedObject(
                coachPayload.isInterestedInReferralProgram,
              )}
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={coachDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              hideSearch={true}
              label="Accepting New Members "
              placeholder=""
              genericData={booleanLookupData}
              onGeneralDataChange={genericData => {
                handleBooleanDropdownChange(
                  genericData,
                  'isTeamAcceptingMembers',
                );
              }}
              genericDefaultValue={selectedObject(
                coachPayload.isTeamAcceptingMembers,
              )}
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={coachDataLoading}
            />
          </GridLayoutItem>
          {coachPayload.hasVerifiedCertificateOfInsurance && (
            <GridLayoutItem>
              <AdminDragAndDropFiles
                onCancel={handleFileOnCancelClick}
                onChange={handleImageSelectInsurance}
                onRemoveFile={handleFileOnCancelClick}
                uploadFile={imageFileInsurence as File}
                isView={isFileUploaded}
                isEdit={imgSrcInsurance !== null}
                uploadStringFile={imgSrcInsurance}
                isHideButtons
              />
              {showInsuranceFileError && (
                <span className="error-msg">Insurance file required</span>
              )}
            </GridLayoutItem>
          )}
        </CustomgridLayoutWrapper>
      </GridWrapper>
    </>
  );
};

const GridWrapper = styled.div`
  padding: 10px 0 20px;
  .k-grid-layout {
    .checkbox {
      margin-left: 15px;
      margin-top: 30px;
      label {
        font-family: 'Inter';
        color: #a3a3a3;
        font-size: 12px;
      }
      @media (min-width: 100px) and (max-width: 1365px) {
        margin-left: 5px;
        margin-right: unset;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    .second-grid {
      grid-template-columns: calc(33.33% - 6px) calc(33.33% - 6px) calc(
          33.33% - 8px
        ) !important;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .Profilecard-grid {
      display: block;
      .profilecard {
        margin-bottom: 20px;
      }
    }
    .common-style {
      grid-template-columns: calc(50% - 5px) calc(50% - 5px) !important;
    }
  }
  @media (min-width: 100px) and (max-width: 767px) {
    .Profilecard-grid {
      display: block;
      .profilecard {
        margin-bottom: 20px;
      }
    }
    .common-style {
      grid-template-columns: calc(100% - 5px) !important;
    }
  }
`;
const CommonWrapper = styled.div`
  .error-msg {
    color: red;
    font-size: 12px;
    font-family: 'Inter';
  }
  .red-star {
    color: red;
    font-weight: bold;
  }
  padding-top: 30px;
  .afiliation-label {
    //Responsive
    @media (min-width: 100px) and (max-width: 319px) {
      .label {
        font-size: 10px;
      }
    }
    @media (min-width: 320px) and (max-width: 575px) {
      .label {
        font-size: 11px;
      }
    }
    @media (min-width: 576px) and (max-width: 1365px) {
      .label {
        font-size: 12px;
      }
    }
  }
`;
