import { GridLayoutItem } from '@progress/kendo-react-layout';
import { CustomgridLayoutWrapper } from 'admin/components/wrappers';
import AdminDropDown from 'app/components/AdminDropDown';
import { AdminTextField } from 'app/components/AdminTextField';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AdminDatePicker } from 'app/components/AdminDatePicker';
import {
  AdminRiderRegistration,
  GeneralLookup,
  GeneralLookupWrapper,
} from 'services/openapi';
import {
  findObjectById,
  isoString,
  getLookupArray,
  isEmptyArray,
  findMatchingDivisionClassAndRemoveSelected,
} from 'utils/common';
import { defaultLookup } from './staticDatas';
import SimpleReactValidator from 'simple-react-validator';
import { FormSkeletonLoader } from 'app/components/FormSkeletonLoader';
import { AdminCheckBox } from 'app/components/AdminCheckbox';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { useParams } from 'react-router-dom';

type Props = {
  riderData: AdminRiderRegistration;
  setRiderdata: React.Dispatch<React.SetStateAction<AdminRiderRegistration>>;
  handleChange: (event: any) => void;
  action: string | undefined;
  lookUps: GeneralLookupWrapper[];
  categoryListFromTeam: GeneralLookup[];
  setCategoryListFromTeam: React.Dispatch<
    React.SetStateAction<GeneralLookup[]>
  >;
  divisionOneList: GeneralLookup[];
  setDivisionOnelist: React.Dispatch<React.SetStateAction<GeneralLookup[]>>;
  divisionTwoList: GeneralLookup[];
  setDivisionTwoList: React.Dispatch<React.SetStateAction<GeneralLookup[]>>;
  simpleValidator: React.MutableRefObject<SimpleReactValidator>;
  setImgSrc: React.Dispatch<React.SetStateAction<string | null>>;
  imgSrc: string | null;
  setImageFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
  riderDataLoading: boolean;
  isCategoryNotInTeam: boolean;
  setIsCategoryNotInTeam: React.Dispatch<React.SetStateAction<boolean>>;
  checkBacknumberTaken: (
    backNumber?: string,
    isTryIt?: boolean,
    riderId?: string,
  ) => void;
  isBacknumberTaken: boolean | null;
  setIsBacknumberTaken: React.Dispatch<React.SetStateAction<boolean | null>>;
};

const RiderInformationFirstBlock = ({
  riderData,
  handleChange,
  setRiderdata,
  action,
  lookUps,
  categoryListFromTeam,
  setCategoryListFromTeam,
  divisionOneList,
  setDivisionOnelist,
  divisionTwoList,
  setDivisionTwoList,
  simpleValidator,
  setImgSrc,
  imgSrc,
  setImageFile,
  riderDataLoading,
  isCategoryNotInTeam,
  setIsCategoryNotInTeam,
  checkBacknumberTaken,
  isBacknumberTaken,
  setIsBacknumberTaken,
}: Props) => {
  const [forceUpdate, setForceUpdate] = useState(false);
  let { id: riderId } = useParams();

  const onDataChangeTeam = e => {
    setRiderdata({
      ...riderData,
      team: e,
      category: defaultLookup,
      divisionClassOne: defaultLookup,
      divisionClassTwo: defaultLookup,
    });
    let teamLookUpList = getLookupArray('ACTIVETEAM', lookUps);
    const matchingObject = findObjectById(teamLookUpList, e.id);
    if (matchingObject) {
      const { children } = matchingObject;
      setCategoryListFromTeam(children!);
      setDivisionOnelist([]);
      setDivisionTwoList([]);
    }
    setIsCategoryNotInTeam(e.children.length === 0 ? true : false);
    const arrays = ['Category', `Division/Classes One`, `Division/Classes Two`];
    e.children.length === 0 &&
      arrays.forEach(field => {
        simpleValidator.current.hideMessageFor(field);
        simpleValidator.current.visibleFields = [];
        // Force re-render to show error messages
        setForceUpdate(!forceUpdate);
      });
  };

  const onDataChangeCategory = e => {
    setRiderdata({
      ...riderData,
      category: e,
      divisionClassOne: defaultLookup,
      divisionClassTwo: defaultLookup,
    });

    let categoryLookUpList = getLookupArray('CATEGORY', lookUps);
    const matchingObject = findObjectById(categoryLookUpList, e.id);
    if (matchingObject) {
      const { children } = matchingObject;
      setDivisionOnelist(children!);
    }
    setDivisionTwoList([]);
  };
  const onDataChangeDivisionOne = e => {
    let division = getLookupArray('DIVISION', lookUps);
    let filteredDivisionTwoList = findMatchingDivisionClassAndRemoveSelected(
      e,
      division,
      divisionOneList,
    ); // for division two dropdown data filtering
    setDivisionTwoList(filteredDivisionTwoList);
    setRiderdata({
      ...riderData,
      divisionClassOne: e,
      divisionClassTwo: defaultLookup,
    });
  };
  const handleImageSelect = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageFile(file);
    }
  };
  const onBackNumberCheck = e => {
    let fieldsData = simpleValidator.current.fields;
    let emailValid = fieldsData['Back Number'];
    setForceUpdate(!forceUpdate);

    if (!emailValid) {
      simpleValidator?.current?.showMessageFor('Back Number');
    } else {
      if (action === 'edit') {
        checkBacknumberTaken(
          riderData?.backNumber ?? '',
          riderData.isTrial,
          riderId,
        );
      } else {
        checkBacknumberTaken(riderData?.backNumber ?? '');
      }
    }
  };
  const handleBackNumberChange = e => {
    const inputValue = e.target.value;
    if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
      setRiderdata?.({
        ...riderData,
        backNumber:
          inputValue === '' ? null : parseFloat(inputValue).toString(),
      });
      if (isBacknumberTaken === false) {
        setIsBacknumberTaken(null);
      }
      setIsBacknumberTaken(null);
    }
  };
  return (
    <>
      <GridWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[{ width: '30%' }, { width: '73.5%' }]}
          gap={{ rows: 20, cols: 30 }}
        >
          <GridLayoutItem>
            <ProfileImageUploadCard
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              onSelectFile={handleImageSelect}
              uploadButtonLabel={`${imgSrc ? 'Change Image' : 'Upload Image'}`}
              uploadButtonType="primary"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <CustomgridLayoutWrapper
              rows={[{ height: 'auto' }]}
              cols={[{ width: '45%' }, { width: '45%' }]}
              gap={{ rows: 20, cols: 10 }}
              className=""
            >
              <GridLayoutItem>
                <AdminTextField
                  label="Rider First Name"
                  placeholder=""
                  value={riderData?.firstName}
                  onChange={handleChange}
                  name="firstName"
                  validations="required|max:100"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={riderDataLoading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="Rider Last Name"
                  placeholder=""
                  value={riderData?.lastName}
                  onChange={handleChange}
                  name="lastName"
                  validations="required|max:100"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={riderDataLoading}
                />
              </GridLayoutItem>
              <GridLayoutItem className="backnumber-wrapper">
                <StyledAdminTextField
                  label="Back Number"
                  placeholder=""
                  value={riderData?.backNumber!}
                  onChange={handleBackNumberChange}
                  name="backNumber"
                  className="field-style"
                  hideStar
                  isLoader={riderDataLoading}
                  validations={
                    riderData.isTrial === true
                      ? 'required|max:2'
                      : 'min:3|max:4|required'
                  }
                  validator={simpleValidator.current}
                />
                {riderDataLoading ? (
                  <div style={{ paddingTop: '30px' }}>
                    <FormSkeletonLoader lineContent={1} textWidth={100} />
                  </div>
                ) : (
                  <AdminCustomButton
                    className="check-button"
                    buttonType="save"
                    onClick={onBackNumberCheck}
                  >
                    Check
                  </AdminCustomButton>
                )}
                {isBacknumberTaken !== null && (
                  <span
                    className={`error-message ${
                      isBacknumberTaken === false
                        ? 'green-border'
                        : 'red-border'
                    }`}
                  >
                    Back number is {isBacknumberTaken === false ? '' : 'not '}
                    available
                  </span>
                )}
              </GridLayoutItem>
              <GridLayoutItem className="backnumber-check-style">
                {riderDataLoading ? (
                  <FormSkeletonLoader lineContent={1} textWidth={100} />
                ) : (
                  <AdminCheckBox
                    label="Make this back number available for others"
                    value={riderData?.isAvailableBackNumber}
                    onChange={e =>
                      setRiderdata({
                        ...riderData,
                        isAvailableBackNumber: e.value,
                      })
                    }
                  />
                )}
              </GridLayoutItem>
              <GridLayoutItem>
                <CustomgridLayoutWrapper
                  rows={[{ height: 'auto' }]}
                  cols={[{ width: '49%' }, { width: '49%' }]}
                  gap={{ rows: 20, cols: 10 }}
                  className="grid-layout-sub"
                >
                  <GridLayoutItem>
                    <AdminTextField
                      label="Rider Height (cm)"
                      placeholder=""
                      value={
                        riderData?.height === null ? '' : riderData?.height!
                      }
                      onChange={e => {
                        const inputValue = e.target.value;
                        if (
                          inputValue === '' ||
                          /^\d{0,3}(\.\d{0,3})?$/.test(inputValue)
                        ) {
                          setRiderdata?.({
                            ...riderData,
                            height:
                              inputValue === '' ? null : parseFloat(inputValue),
                          });
                        }
                      }}
                      type="number"
                      name="height"
                      className="field-style"
                      validator={simpleValidator.current}
                      validations="numeric|min:50,num|max:300,num"
                      isLoader={riderDataLoading}
                      min={50}
                      max={300}
                      hideStar
                      maxLength={3}
                      onKeyDown={e => {
                        if (['e', 'E', '+', '-'].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <AdminTextField
                      label="Rider Weight (kg)"
                      placeholder=""
                      value={
                        riderData?.weight === null ? '' : riderData?.weight!
                      }
                      onChange={e => {
                        const inputValue = e.target.value;
                        if (
                          inputValue === '' ||
                          /^\d{0,3}(\.\d{0,3})?$/.test(inputValue)
                        ) {
                          setRiderdata?.({
                            ...riderData,
                            weight:
                              inputValue === '' ? null : parseFloat(inputValue),
                          });
                        }
                      }}
                      name="weight"
                      validator={simpleValidator.current}
                      validations="numeric|min:30,num|max:300,num"
                      type="number"
                      className="field-style"
                      isLoader={riderDataLoading}
                      min={30}
                      max={300}
                      hideStar
                      maxLength={3}
                      onKeyDown={e => {
                        if (['e', 'E', '+', '-'].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </GridLayoutItem>
                </CustomgridLayoutWrapper>
              </GridLayoutItem>
              <GridLayoutItem>
                <CustomgridLayoutWrapper
                  rows={[{ height: 'auto' }]}
                  cols={[{ width: '49%' }, { width: '49%' }]}
                  gap={{ rows: 20, cols: 10 }}
                  className="grid-layout-sub"
                >
                  <GridLayoutItem className="team-style">
                    <AdminDropDown
                      label="Team "
                      placeholder=""
                      data={getLookupArray('ACTIVETEAM', lookUps)}
                      onDataChange={onDataChangeTeam}
                      defaultValue={riderData?.team}
                      validations="required"
                      validator={simpleValidator.current}
                      className="field-style"
                      isLoader={riderDataLoading}
                    />
                    {isCategoryNotInTeam && (
                      <span className="team-error-message">
                        Selected team is not available,choose another team
                      </span>
                    )}
                  </GridLayoutItem>
                  <GridLayoutItem>
                    {riderDataLoading ? (
                      <div className="team-checkbox-style">
                        <FormSkeletonLoader lineContent={1} textWidth={100} />
                      </div>
                    ) : (
                      <AdminCheckBox
                        label="Transferred team mid-season"
                        className="team-checkbox-style"
                        value={riderData?.isTransferredTeam}
                        onChange={e =>
                          setRiderdata({
                            ...riderData,
                            isTransferredTeam: e.value,
                          })
                        }
                      />
                    )}
                  </GridLayoutItem>
                </CustomgridLayoutWrapper>
              </GridLayoutItem>
            </CustomgridLayoutWrapper>
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
      </GridWrapper>
      <Wrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[{ width: '30%' }, { width: '33.4%' }, { width: '33.4%' }]}
          gap={{ rows: 20, cols: 10 }}
        >
          <GridLayoutItem>
            <AdminDropDown
              label="Category "
              placeholder=""
              data={categoryListFromTeam}
              onDataChange={onDataChangeCategory}
              defaultValue={riderData?.category}
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              disabled={isEmptyArray(categoryListFromTeam)}
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              label="Division/Classes One"
              placeholder=""
              data={divisionOneList}
              onDataChange={onDataChangeDivisionOne}
              defaultValue={riderData?.divisionClassOne! ?? undefined}
              className="field-style"
              disabled={isEmptyArray(divisionOneList)}
              validations="required"
              validator={simpleValidator.current}
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              label="Division/Classes Two"
              placeholder=""
              data={divisionTwoList}
              onDataChange={data => {
                setRiderdata({
                  ...riderData,
                  divisionClassTwo: data,
                });
              }}
              defaultValue={riderData?.divisionClassTwo ?? undefined}
              className="field-style"
              disabled={isEmptyArray(divisionTwoList)}
              validations="required"
              validator={simpleValidator.current}
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              label="Status "
              placeholder=""
              data={getLookupArray('ACTIVESTATUS', lookUps)}
              onDataChange={data => {
                setRiderdata({
                  ...riderData,
                  riderStatus: data,
                });
              }}
              defaultValue={riderData?.riderStatus}
              validations="required"
              validator={simpleValidator.current}
              className="field-style status-dropdown"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDatePicker
              title="Created Date "
              placeholder="day/month/year"
              value={
                riderData?.adminCreatedDate
                  ? new Date(riderData.adminCreatedDate)
                  : undefined
              }
              onChange={e =>
                setRiderdata({
                  ...riderData,
                  adminCreatedDate: isoString(e.value),
                })
              }
              validations="required"
              validator={simpleValidator.current}
              className="field-style create-date"
              mindate={new Date()}
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          {riderDataLoading ? (
            <FormSkeletonLoader lineContent={1} textWidth={100} />
          ) : (
            <GridLayoutItem>
              <div className="checkbox">
                <AdminCheckBox
                  label="Try-it"
                  value={riderData?.isTrial}
                  onChange={e =>
                    setRiderdata({
                      ...riderData,
                      isTrial: e.value,
                    })
                  }
                />
              </div>
            </GridLayoutItem>
          )}
        </CustomgridLayoutWrapper>
      </Wrapper>
    </>
  );
};

export default RiderInformationFirstBlock;
const GridWrapper = styled.div`
  margin-bottom: 20px;
  .team-checkbox-style {
    padding-top: 30px;
    label {
      color: #a3a3a3;
    }
  }
  @media (min-width: 1899px) and (max-width: 4000px) {
    .k-grid-layout {
      gap: 20px 15px !important;
    }
    .grid-layout-sub {
      grid-template-columns: 49% 48.5% !important;
    }
  }
  @media (max-width: 991px) {
    .k-grid-layout {
      grid-template-columns: 100% !important;
    }
  }
  .team-style {
    position: relative;
    .team-error-message {
      position: absolute;
      top: 72px;
      color: #f44336;
      font-size: 12px;
      width: max-content;
    }
  }
  .backnumber-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    .check-button {
      width: calc(25% - 5px);
      text-align: center;
      height: 100%;
      align-content: flex-start;
      padding-top: 30px;
      @media (min-width: 100px) and (max-width: 1290px) {
        button {
          padding: 0 5px !important;
        }
      }
    }
    .error-message {
      color: #f44336;
      font-size: 12px;
      display: inline-block;
      position: absolute;
      bottom: -18px;
      &.green-border {
        color: #3cb33c !important;
        font-size: 12px;
        display: inline-block;
      }
    }
  }
  .backnumber-check-style {
    align-content: flex-start;
    padding-top: 30px;
    label {
      color: #a3a3a3;
    }
  }
`;
const Wrapper = styled.div`
  .status-dropdown {
    height: 90px;
  }
  .create-date {
    height: 90px;
  }
  margin-bottom: 20px;
  .k-grid-layout {
    align-items: center;
    .checkbox {
      margin-left: 15px;
      .k-checkbox {
        background-color: #fff !important;
      }
      input[type='checkbox']:checked {
        background-color: #4764ff !important;
      }
      label {
        font-family: 'Inter';
        color: #a3a3a3;
        font-size: 12px;
      }
    }
  }
  @media (max-width: 991px) {
    .k-grid-layout {
      gap: 20px 15px !important;
      grid-template-columns: 50% 50% !important;
    }
  }
`;
const StyledAdminTextField = styled(AdminTextField)`
  width: calc(100% - 75px);
`;
