import AdminDropDown from 'app/components/AdminDropDown';
import { AdminTextField } from 'app/components/AdminTextField';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import {
  Dispatch,
  MouseEvent,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  FinancialResponsiblity,
  GeneralLookup,
  GeneralLookupWrapper,
  TeamRequest,
} from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import { AdminEditIcon } from 'admin/assets/icon';
import { useLocation, useParams } from 'react-router-dom';
import { H2LabelSemiBold, SubLabelSemiBold } from 'app/components/SHLabel';
import { AdminTextArea } from 'app/components/AdminTextArea';
import {
  capitalizeFirstLetter,
  getLookupArray,
  seasonValue,
} from 'utils/common';
import { AdminCheckBox } from 'app/components/AdminCheckbox';
import { financialResInitialData } from '../../staticDefaultData';
import { CardSkeletonLoader } from 'app/components/CardSkeletonLoader';
import { AdminMultiSelectDropDown } from 'app/components/AdminMultiSelectDropDown';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
  isDisabled?: boolean;
  teamProfileData: TeamRequest;
  setTeamProfileData: Dispatch<SetStateAction<TeamRequest>>;
  setIsDisabled?: Dispatch<SetStateAction<boolean>>;
  onEditClick?:
    | ((event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void)
    | undefined;
  setImageUploadBlobPayload?: Dispatch<SetStateAction<Blob | null>>;
  simpleValidator?: MutableRefObject<SimpleReactValidator>;
  setTeamSigData: Dispatch<SetStateAction<FinancialResponsiblity | undefined>>;
  teamSigData: FinancialResponsiblity | undefined;
  financialDefault?: GeneralLookup | undefined;
  setFinancialDefault?: Dispatch<SetStateAction<GeneralLookup | undefined>>;
  lookUps: GeneralLookupWrapper[];
  loading: boolean;
  setImgSrc: Dispatch<SetStateAction<string | null>>;
  imgSrc: string | null;
  teamCodeValue?: string;
  generateTeamCode: (teamName?: string) => void;
  setTeamCodeValue?: Dispatch<SetStateAction<string | undefined>>;
};

export const teamProfileDefault: TeamRequest = {
  id: null,
  teamName: '',
  teamImageUrl: '',
  description: '',
  teamShortName: '',
  mainContactName: '',
  contactEmail: '',
  contactPhone: '',
  contactAddress: '',
  contactCity: '',
  contactState: { id: '', name: '', children: null },
  zip: '',
  teamStatus: { id: '', name: '', children: null },
  liveRankingLink: '',
  season: { id: '', name: '', children: null },
  finanacialResponsibleSignature: false,
  financialResponsibility: {
    name: '',
    phone: '',
    street: '',
    city: '',
    state: { id: '', name: '', children: null },
    zip: '',
  },
  isTrial: false,
};
const financialLookup = [
  { id: '0', name: 'Yes', children: null },
  { id: '1', name: 'No', children: null },
];
export const TeamProfile = ({
  isDisabled,
  teamProfileData,
  setTeamProfileData,
  onEditClick,
  setImageUploadBlobPayload,
  simpleValidator,
  setTeamSigData,
  teamSigData,
  financialDefault,
  setFinancialDefault,
  lookUps,
  loading,
  setImgSrc,
  imgSrc,
  generateTeamCode,
  teamCodeValue,
  setTeamCodeValue,
}: Props) => {
  const location = useLocation();
  const { action } = useParams();
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const handleChange = event => {
    const { name, value } = event.target;
    if (name === 'teamName') {
      setTeamCodeValue?.('');
    }

    setTeamProfileData(prevData => {
      if (name === 'teamName') {
        return {
          ...prevData,
          teamName: value,
          teamShortName: '',
        };
      }
      return {
        ...prevData,
        [name]: value,
      };
    });
  };
  const handleFinancialChange = event => {
    const { name, value } = event.target;
    if (teamSigData) {
      setTeamSigData?.({ ...teamSigData, [name]: value });
    }
  };
  const handleImageSelect = e => {
    if (e.target.files && e.target.files.length > 0) {
      let fileUrl = URL.createObjectURL(e?.target?.files?.[0]);
      setTeamProfileData(prevData => ({
        ...prevData,
        teamImageUrl: fileUrl,
      }));
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageUploadBlobPayload?.(file);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [preDataList, setPreDataList] = useState<GeneralLookup[]>([]);

  const handleCategoryRemove = (selectedList: any, selectedItem: any) => {
    const updatedPreDataList = teamProfileData?.category?.filter(
      item => item.id !== selectedItem.id,
    );
    setTeamProfileData({ ...teamProfileData, category: updatedPreDataList });
    setPreDataList(updatedPreDataList!);
  };

  const handleUserTypeSelect = (selectedList: any, selectedItem: any) => {
    setTeamProfileData({
      ...teamProfileData,
      category: selectedList,
    });
    setPreDataList(selectedList);
  };

  useEffect(() => {
    setPreDataList(teamProfileData?.category!);
  }, [teamProfileData]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isSmallScreen = screenSize.width < 1199;
  const finacialSigDefault =
    (action === 'view' || action === 'edit') &&
    teamProfileData?.finanacialResponsibleSignature === true
      ? { id: '0', name: 'Yes', children: null }
      : (action === 'view' || action === 'edit') &&
        teamProfileData?.finanacialResponsibleSignature === false
      ? { id: '1', name: 'No', children: null }
      : action === 'add' && financialDefault === undefined
      ? { id: '', name: '', children: null }
      : action === 'add' &&
        teamProfileData?.finanacialResponsibleSignature === true
      ? { id: '0', name: 'Yes', children: null }
      : { id: '1', name: 'No', children: null };

  useEffect(() => {
    if (
      teamCodeValue !== 'ChooseAnotherName' &&
      teamCodeValue !== 'NameIsAlreadyExist'
    ) {
      setTeamProfileData(prevData => ({
        ...prevData,
        teamShortName: teamCodeValue,
      }));
    }
  }, [teamCodeValue]);
  return (
    <Wrapper>
      {loading && isDisabled ? (
        <CardSkeletonLoader
          isProfileImageLoader
          isTextLoader
          lineContent={3}
          isHeaderLoader
          headerWidth={300}
        />
      ) : (
        <>
          {isDisabled && (
            <ProfileImageUploadCard
              className={`ProfileImageUploadCard`}
              buttonLabel={'Edit'}
              svgIcon={<AdminEditIcon />}
              buttonType="secondary"
              onButtonClick={onEditClick}
              imgSrc={teamProfileData?.teamImageUrl}
              setImgSrc={setImgSrc}
              isLoader={loading}
              isPastDataFlag={isPastDataFlag}
            >
              <H2LabelSemiBold
                className="header-title"
                color="rgba(0, 0, 0, 1)"
              >
                {capitalizeFirstLetter(teamProfileData?.teamName) ?? ''}
              </H2LabelSemiBold>
              <p className="para">{teamProfileData?.description}</p>
            </ProfileImageUploadCard>
          )}
        </>
      )}
      <GridWrapper>
        {!isDisabled && (
          <ProfileNameWrapper>
            <ProfileImageUploadCard
              className="ProfileImageUploadCard"
              uploadButtonLabel={`${imgSrc ? 'Change Image' : 'Upload Image'}`}
              svgIcon={<AdminEditIcon />}
              uploadButtonType="uploadFile"
              imgSrc={`${
                action === 'edit' && teamProfileData?.teamImageUrl?.length !== 0
                  ? teamProfileData?.teamImageUrl
                  : imgSrc
              }`}
              setImgSrc={setImgSrc}
              onSelectFile={handleImageSelect}
              isLoader={loading}
              isPastDataFlag={isPastDataFlag}
            ></ProfileImageUploadCard>
            <NameDesrciptionWrapper>
              <div className="textfield-style">
                <AdminTextField
                  label="Team Name"
                  placeholder=""
                  value={capitalizeFirstLetter(teamProfileData?.teamName) ?? ''}
                  onChange={handleChange}
                  name="teamName"
                  validations="required|max:100"
                  validator={simpleValidator?.current}
                  className="field-style add-style"
                  disabled={isDisabled}
                  isLoader={loading}
                />
                <AdminTextField
                  label="Team Code"
                  placeholder=""
                  value={teamProfileData?.teamShortName ?? ''}
                  onChange={() => {}}
                  name="teamShortName"
                  validations="required|max:100"
                  validator={simpleValidator?.current}
                  disabled={isDisabled}
                  className={`field-style team-code-style ${
                    isSmallScreen ? 'hide-field' : ''
                  }`}
                  isLoader={loading}
                  readOnly={true}
                />
                <Button
                  className="btn-generate"
                  onClick={() => {
                    generateTeamCode(teamProfileData.teamName);
                  }}
                  disabled={teamProfileData.teamName === ''}
                >
                  Generate
                </Button>
              </div>
              <AdminTextArea
                label="Description"
                placeholder=""
                value={teamProfileData?.description}
                onChangeText={handleChange}
                name="description"
                validations="required|max:500"
                validator={simpleValidator?.current}
                className="field-style add-style description-style"
                disabled={isDisabled}
                isLoader={loading}
              />
            </NameDesrciptionWrapper>
          </ProfileNameWrapper>
        )}
        <AdminTextField
          label="Live Ranking Link"
          placeholder=""
          value={teamProfileData?.liveRankingLink ?? ''}
          name="liveRankingLink"
          onChange={handleChange}
          className="field-style"
          disabled={isDisabled}
          isLoader={loading}
        />
        <AdminMultiSelectDropDown
          label="Team Category"
          placeholder=""
          onRemove={handleCategoryRemove}
          onSelect={handleUserTypeSelect}
          data={getLookupArray('TEAM_CATEGORY', lookUps)}
          setPreDataList={setPreDataList}
          preDataList={preDataList}
          className={'MHMultiCheck'}
          validations="required|max:100"
          validator={simpleValidator?.current}
          isLoader={loading}
          disabled={isDisabled}
        />
        <div className="checkbox">
          <AdminCheckBox
            label="Try-It ?"
            value={teamProfileData?.isTrial}
            onChange={e => {
              if (action === 'add' || action === 'edit') {
                setTeamProfileData({
                  ...teamProfileData,
                  isTrial: e.value,
                });
              }
            }}
            className={`${
              action === 'add' || action === 'edit'
                ? 'cursor-allowed'
                : 'cursor-not-allowed'
            }`}
            isLoader={loading}
          />
        </div>
        <AdminTextField
          label="Team Code"
          placeholder=""
          value={teamProfileData?.teamShortName ?? ''}
          onChange={handleChange}
          name="teamShortName"
          validations="required|max:100"
          validator={simpleValidator?.current}
          disabled={isDisabled}
          className={`field-style ${
            action === 'view' || isSmallScreen ? '' : 'hide-field'
          }`}
          isLoader={loading}
        />
        <AdminTextField
          label="Contact Email"
          placeholder=""
          value={teamProfileData?.contactEmail ?? ''}
          name="contactEmail"
          onChange={handleChange}
          validations="required|email|max:100"
          validator={simpleValidator?.current}
          className="field-style"
          disabled={isDisabled}
          isLoader={loading}
        />
        <AdminTextField
          label="Contact Phone/Mobile"
          placeholder=""
          value={teamProfileData?.contactPhone ?? ''}
          name="contactPhone"
          onChange={e => {
            const inputValue = e.target.value;
            if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
              setTeamProfileData?.({
                ...teamProfileData,
                contactPhone: inputValue,
              });
            }
          }}
          validations={'required|numeric|min:10|max:10'}
          validator={simpleValidator?.current}
          className="field-style"
          disabled={isDisabled}
          isLoader={loading}
        />
        <AdminTextField
          label="Contact Address"
          placeholder=""
          value={teamProfileData?.contactAddress ?? ''}
          name="contactAddress"
          onChange={handleChange}
          validations="required|max:100"
          validator={simpleValidator?.current}
          className="field-style"
          disabled={isDisabled}
          isLoader={loading}
        />
        <AdminTextField
          label="Contact City"
          placeholder=""
          value={teamProfileData?.contactCity ?? ''}
          name="contactCity"
          onChange={handleChange}
          validations="required|max:100"
          validator={simpleValidator?.current}
          className="field-style"
          disabled={isDisabled}
          isLoader={loading}
        />
        <AdminDropDown
          label="Contact State"
          placeholder=""
          data={getLookupArray('STATE', lookUps)}
          onDataChange={data => {
            setTeamProfileData({
              ...teamProfileData,
              contactState: data,
            });
          }}
          defaultValue={teamProfileData?.contactState}
          validations="required|max:100"
          validator={simpleValidator?.current}
          className="field-style"
          disabled={isDisabled}
          isLoader={loading}
        />
        <AdminTextField
          label="Zip"
          placeholder=""
          value={teamProfileData?.zip ?? ''}
          name="zip"
          onChange={handleChange}
          validations="required|min:5|max:10"
          validator={simpleValidator?.current}
          className="field-style"
          disabled={isDisabled}
          isLoader={loading}
        />
        {location.pathname.includes('past-data') ? (
          ''
        ) : (
          <AdminDropDown
            label="Season"
            placeholder=""
            data={getLookupArray('SEASON', lookUps)}
            onDataChange={data => {
              setTeamProfileData({
                ...teamProfileData,
                season: data,
              });
            }}
            defaultValue={
              action === 'update'
                ? teamProfileData?.season
                : seasonValue(lookUps)
            }
            validations="required|max:100"
            validator={simpleValidator?.current}
            className="field-style"
            disabled
            isLoader={loading}
          />
        )}
        <AdminDropDown
          label="Team Status"
          placeholder=""
          data={getLookupArray('ACTIVESTATUS', lookUps)}
          onDataChange={data => {
            setTeamProfileData({ ...teamProfileData, teamStatus: data });
          }}
          defaultValue={teamProfileData?.teamStatus}
          validations="required|max:100"
          validator={simpleValidator?.current}
          className="field-style"
          disabled={isDisabled}
          isLoader={loading}
        />
        <AdminDropDown
          label="Financial Resp Signature"
          placeholder=""
          data={financialLookup}
          onDataChange={data => {
            setFinancialDefault?.(data);
            if (data.id === '1') {
              setTeamSigData(financialResInitialData);
              setTeamProfileData({
                ...teamProfileData,
                financialResponsibility: undefined,
                finanacialResponsibleSignature: false,
              });
            } else {
              setTeamProfileData({
                ...teamProfileData,
                finanacialResponsibleSignature: true,
                financialResponsibility: financialResInitialData,
              });
              setTeamSigData(financialResInitialData);
            }
          }}
          defaultValue={finacialSigDefault}
          validations="required|max:100"
          validator={simpleValidator?.current}
          className="field-style"
          disabled={isDisabled}
          isLoader={loading}
        />
        {teamProfileData?.finanacialResponsibleSignature === true && (
          <SubGridWrapper>
            <SubLabelSemiBold className="sub-title">
              Financial Responsibility
            </SubLabelSemiBold>
            <p className="fincacial-para">
              In order to qualify as a YEDA Team, at least one member of the
              team’s Coaching Staff, School, Farm, or other named Individual
              must sign the below indicating financial responsibility for the
              team and any debts incurred.
            </p>
            <AdminTextField
              label="Name"
              placeholder=""
              value={teamSigData?.name}
              name="name"
              onChange={handleFinancialChange}
              className="field-style"
              disabled={isDisabled}
              validator={simpleValidator?.current}
              validations={'required|max:100'}
              isLoader={loading}
            />
            <AdminTextField
              label="Phone Number"
              placeholder=""
              value={teamSigData?.phone}
              name="phone"
              onChange={e => {
                const inputValue = e.target.value;
                if (
                  teamSigData &&
                  inputValue.length <= 15 &&
                  /^\d*$/.test(inputValue)
                ) {
                  setTeamSigData?.({
                    ...teamSigData,
                    phone: inputValue,
                  });
                }
              }}
              className="field-style"
              disabled={isDisabled}
              validator={simpleValidator?.current}
              validations={'required|numeric|min:10|max:10'}
              isLoader={loading}
            />
            <AdminTextField
              label="Street Address"
              placeholder=""
              value={teamSigData?.street}
              name="street"
              onChange={handleFinancialChange}
              className="field-style"
              disabled={isDisabled}
              validator={simpleValidator?.current}
              validations={'required|max:300'}
              isLoader={loading}
            />
            <AdminTextField
              label="City"
              placeholder=""
              value={teamSigData?.city}
              name="city"
              onChange={handleFinancialChange}
              className="field-style"
              disabled={isDisabled}
              validator={simpleValidator?.current}
              validations={'required|max:100'}
              isLoader={loading}
            />
            <AdminDropDown
              label="State"
              placeholder=""
              data={getLookupArray('STATE', lookUps)}
              onDataChange={data => {
                if (teamSigData)
                  setTeamSigData({ ...teamSigData, state: data });
              }}
              defaultValue={teamSigData?.state}
              className="field-style"
              disabled={isDisabled}
              validator={simpleValidator?.current}
              validations={'required|max:100'}
              isLoader={loading}
            />
            <AdminTextField
              validationLabel="zip"
              label="Zip"
              placeholder=""
              value={teamSigData?.zip}
              name="zip"
              onChange={handleFinancialChange}
              className="field-style"
              disabled={isDisabled}
              validator={simpleValidator?.current}
              validations={'required|min:5|max:10'}
              isLoader={loading}
            />
          </SubGridWrapper>
        )}
      </GridWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .header-title {
    margin: 0px;
    font-size: 22px;
    font-family: 'Inter';
    padding: 42px 0px 7px;
    line-height: 26.63px;
    font-weight: 500;
  }
  .sub-title {
    font-size: 15px;
    font-family: 'Inter';
    font-weight: 600;
    line-height: 26.63px;
  }
  .hide-button {
    button {
      display: none;
    }
  }
  .para {
    font-family: 'Inter';
    color: rgba(146, 146, 146, 1);
    margin: 0px;
    font-size: 15px;
    overflow: hidden;
    line-height: 18.15px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .child-wrapper {
    display: block !important;
  }
`;
const GridWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: calc(33.33% - 6.7px) calc(33.33% - 6.7px) calc(
      33.33% - 6.7px
    );
  column-gap: 10px;
  padding-top: 30px;
  .hide-field {
    display: none;
  }
  .field-style {
    margin-bottom: 20px;
  }
  .checkbox {
    margin-left: 15px;
    margin-top: 28px;
    .cursor-allowed {
      input[type='checkbox'] {
        cursor: pointer;
      }
    }
    .cursor-not-allowed {
      input[type='checkbox'] {
        cursor: default;
      }
    }
    label {
      font-family: 'Inter';
      color: #a3a3a3;
      font-size: 13px;
      font-weight: 500;
    }
    @media (min-width: 100px) and (max-width: 1365px) {
      margin-left: 5px;
      margin-right: unset;
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 100px) and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    .live-link-style {
      grid-column: unset !important;
    }
  }
  .live-link-style,
  .description-style {
    grid-column: span 2;
  }

  .label {
    font-size: 13px !important;
    font-weight: 500;
  }
  .hCOODp input {
    font-size: 14px;
  }
`;
const SubGridWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-column: span 3;
  column-gap: 10px;
  &.name-desciption-wrapper {
    align-items: end;
  }
  .header-title {
    margin: 0px;
  }
  .fincacial-para {
    grid-column: span 3;
    font-size: 14px;
    font-family: 'Inter';
  }
  grid-template-columns: calc(33.33% - 6.2px) calc(33.33% - 6.2px) calc(
      33.33% - 6.2px
    );
  .live-link-style {
    grid-column: span 1;
  }
`;
const NameDesrciptionWrapper = styled.div`
  width: 66.66%;
  display: grid;
  grid-column: span 2 !important;
  grid-template-columns: calc(50%) calc(50%);
  .team-code-style {
    width: calc(100% - 136px);
    flex: unset !important;
  }
  .btn-generate {
    height: 43px;
    box-shadow: none;
    outline: none;
    margin-top: 25px;
    padding: 0 15px;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    width: 50%;
  }
  @media (min-width: 100px) and (max-width: 767px) {
    width: 100%;
    grid-template-columns: calc(100%);
  }
`;
const ProfileNameWrapper = styled.div`
  display: flex;
  .ProfileImageUploadCard {
    margin-bottom: 20px;
    width: calc(33.33% - 6px);
  }
  .textfield-style {
    display: flex;
    width: 100%;
    gap: 10px;
    grid-column: span 2;
    .field-style {
      width: 50%;
    }
  }
  .small-screen {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    grid-column: span 2;
    .textfield-style {
      display: block;
      .field-style {
        width: 100%;
      }
    }

    .ProfileImageUploadCard {
      width: 50%;
    }
  }
  @media (min-width: 100px) and (max-width: 767px) {
    grid-column: span 1;
    display: block;
    .ProfileImageUploadCard {
      width: 100%;
    }
    .field-style {
      width: 100% !important;
    }
  }
  grid-column: span 3;
  width: 100%;
  gap: 10px;
`;
