import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { TextField } from 'app/components/TextField';
import SHdropdown from 'app/components/SHdropdown';
import { useNavigate } from 'react-router-dom';
import { BackNextButton } from 'app/components/BackNextButton';
import { EWD, GeneralLookup, GeneralLookupWrapper } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  riderData?: EWD | undefined;
  setRiderData: React.Dispatch<React.SetStateAction<EWD>>;
  stateData: GeneralLookup[] | undefined;
  onClickEqupmentDetailsNext: () => void;
  onClickEqupmentDetailsBack: () => void;
  setTeamIdPayload: (
    value: React.SetStateAction<GeneralLookup | undefined>,
  ) => void;
  teamIdPayload: GeneralLookup | undefined;
  lookUp: GeneralLookupWrapper[] | undefined;
};

export const EquipmentDetailsReturning = ({
  riderData,
  setRiderData,
  stateData,
  onClickEqupmentDetailsNext,
  onClickEqupmentDetailsBack,
  setTeamIdPayload,
  teamIdPayload,
  lookUp,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(false);
  const handleChange = event => {
    const { name, value } = event.target;
    console.log('changed', value);
    setRiderData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [riderData]);
  return (
    <MainWrapper>
      <p className="first-para">
        A membership registration forms for Equestrian with Disabilities must be
        accompanied by a Diagnosis and Adaptive Equipment Form.
      </p>
      <OverflowWrapper>
        <TextFieldGrid>
          <StyledTextField
            onChange={handleChange}
            value={riderData?.firstName}
            label="First Name"
            type="text"
            name="firstName"
            validations={'required|max:30'}
            validator={simpleValidator.current}
          />
          <StyledTextField
            onChange={handleChange}
            value={riderData?.lastName}
            label="Last Name"
            type="text"
            name="lastName"
            validations={'required|max:30'}
            validator={simpleValidator.current}
          />
          <DropdownWrapper>
            <SHdropdown
              className="drop-down"
              label="Team Name"
              onDataChange={data => setTeamIdPayload(data)}
              data={lookUp?.find(obj => obj.type === 'TEAM')?.values ?? []}
              defaultValue={teamIdPayload!}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </DropdownWrapper>
          <StyledTextField
            onChange={handleChange}
            value={riderData?.primaryPhone}
            label="Phone Number"
            type="number"
            name="primaryPhone"
            validations={'required|min:10|max:10'}
            validator={simpleValidator.current}
          />
        </TextFieldGrid>
        <MainAddressWrapper>
          <div className="horizontal-line">
            <span>Rider's Address</span>
          </div>
          <TextFieldGrid>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.street}
              label="Street Address"
              type="text"
              name="street"
              validations={'required'}
              validator={simpleValidator.current}
            />
            <StyledTextField
              onChange={handleChange}
              value={riderData?.city}
              label="City"
              type="text"
              name="city"
              validations={'required|max:40'}
              validator={simpleValidator.current}
              maxLength={40}
            />
            <DropdownWrapper>
              <SHdropdown
                className="drop-down"
                label="State"
                data={stateData}
                onDataChange={data => {
                  if (riderData) {
                    setRiderData!({
                      ...riderData,
                      state: data,
                    });
                  }
                }}
                defaultValue={riderData?.state}
                validations={'required'}
                validator={simpleValidator.current}
              />
            </DropdownWrapper>
            <StyledTextField
              onChange={handleChange}
              value={riderData?.zip}
              label="Zip"
              type="number"
              name="zip"
              validations={'required|min:5|max:9'}
              validator={simpleValidator.current}
            />
            <StyledTextField
              onChange={handleChange}
              value={riderData?.emergencyContactName!}
              label="Emergency Contact Name"
              type="text"
              name="emergencyContactName"
              validations={'required'}
              validator={simpleValidator.current}
            />
            <StyledTextField
              onChange={handleChange}
              value={riderData?.email}
              label=" Email"
              type="email"
              name="email"
              validations={'required|email'}
              validator={simpleValidator.current}
              maxLength={60}
            />
          </TextFieldGrid>
        </MainAddressWrapper>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickEqupmentDetailsNext}
            onClickBack={onClickEqupmentDetailsBack}
            next="Next"
            back="Cancel"
            disableNext={valid}
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 150px);
  overflow: auto;
  padding-top: 50px;

  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 40px;
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding-top: 45px;
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding-top: 50px;
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  padding-bottom: 20px;

  @media (min-width: 992px) and (max-width: 1199px) {
    gap: 5px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    gap: 10px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    gap: 15px;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 20px !important;
`;
const MainAddressWrapper = styled.div`
  padding-bottom: 50px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 0 15px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const DropdownWrapper = styled.div`
  flex: 0 0 calc(50% - 20px);
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .drop-down {
    margin: 0px;
    width: 100% !important;
    height: 35px;
    color: #9597a6;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  button {
    width: 100px;
    height: 30px;
    margin: 0px 10px;
    border-radius: 6px;
  }
  .btn-back {
    background-color: #2a2a2a;
    border: 1px solid #606060;
    color: #909090;
  }
  .btn-nxt {
    background-color: #306fdb;
    border: 1px solid #2a2a2a;
    color: #ffffff;
  }
  .btn-rgstr {
    background-color: #306fdb;
    border: 1px solid #2a2a2a;
    color: #ffffff;
  }

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 1px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
    @media (min-width: 1200px) and (max-width: 1365px) {
      height: 55px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      height: 70px;
    }
  }
`;
