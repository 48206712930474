import { BackNextButton } from 'app/components/BackNextButton';
import { TextField } from 'app/components/TextField';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RiderTable from './components/RiderTable';
import RiderInformation from './components/RiderInformation';
import { useNavigate } from 'react-router-dom';
import SHdropdown, { DropDownModel } from 'app/components/SHdropdown';
import {
  ApiError,
  GeneralLookup,
  GeneralRider,
  RegisterRider,
  RiderDivisionResponse,
  RiderService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import { useDispatch, useSelector } from 'react-redux';
import SearchButton from 'app/components/SearchButton';
import Cookies from 'js-cookie';
import SimpleReactValidator from 'simple-react-validator';
import CircularLoader from 'app/components/CircularLoader';
import {
  selectNewRiderData,
  selectPhotoUpload,
  selectPlacementRadioValue,
  selectPreviousEnrolData,
  selectPreviousEnrolRadioValue,
} from '../../slice/selectors';
import { useManagePageSlice } from 'app/pages/slice';
import { selectPageLoader } from 'app/pages/slice/selectors';
import { useRiderRegistrationStateSlice } from '../../slice';
import { heightWeightModel } from '../NotReturningRider';
import isApiError from 'utils/isApiError';
import { useErrorHandlingSlice } from 'app/error/slice';
import {
  defaultRiderData,
  radioModel,
  teamValueDefault,
} from './components/RiderInformation/staticData';
import { useRiderRegistrationApi } from 'hooks/useRiderRegistrationApi';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import {
  defaultValue,
  lookipCategoryDefaultValue,
  periviousValue,
  placementRadioValuesDefaultValue,
  prevRadioModel,
  previousEnrolRadioValuesDefaultvalue,
  searchedBy,
} from '../staticData';
import { defaultLookup } from 'admin/pages/RiderIndividual/components/RiderCreatePage/staticDatas';
import { Button } from '@progress/kendo-react-buttons';

const ReturningRider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error: apiError, handleRequest } = useApi();
  const { actions: ErrorHandlingActions } = useErrorHandlingSlice();
  const newRiderData = useSelector(selectNewRiderData);
  const newRiderPreviousEnrolData = useSelector(selectPreviousEnrolData);
  const previousEnrolRadioDataRedux = useSelector(
    selectPreviousEnrolRadioValue,
  );
  const placementRadioDataRedux = useSelector(selectPlacementRadioValue);
  const photoUploadDataRedux = useSelector(selectPhotoUpload);
  const [, setError] = React.useState<ApiError | unknown>();
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const { riderAddNewRider, riderUploadRiderPhoto } = RiderService;
  const {
    getRidersList,
    riderSearchList,
    loading,
    getRiderInfo,
    riderInfo: riderDetails,
    getRiderBackNumberList,
    riderBackNumberList,
    getAllLookupByCategory,
    teamLookup,
  } = useRiderRegistrationApi();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [backNumber, setBackNumber] = useState('');
  const [riderTableData, setRidertableData] = useState<Array<GeneralRider>>();
  const [showTable, setShowTable] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [riderId, setRiderId] = useState<string | undefined>();
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [riderData, setRiderData] = useState<RegisterRider>(defaultRiderData);
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const [teamFilteredCategory, setTeamFilteredCategory] =
    useState<GeneralLookup[]>();
  const [values, setValues] = React.useState<number>(0);
  const [nameValue, setNameChange] = useState('');
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState<boolean>();
  const [categoryDatas, setCategoryDatas] = useState<GeneralLookup[]>();
  const [divisionDatas, setDivisionDatas] = useState<GeneralLookup[]>();
  const [categoryDefaultDatas, setCategoryDefaultDatas] =
    useState<GeneralLookup[]>();
  const [seasonId, setSeasonId] = useState<string>();
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const [previousData, setPreviousData] = useState({
    seasonList: periviousValue,
    category: periviousValue,
    division: periviousValue,
  });
  const [seasonDivison, setSeasonDivision] = useState<
    (string[] | undefined)[] | undefined
  >([]);
  const [riderDivisionRes, setRiderDivisionRes] =
    useState<RiderDivisionResponse>();
  const [filteredSeasonLookupData, setFilteredSeasonLookupData] = useState<
    GeneralLookup[]
  >([]);
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [placementRadioValues, setPlacementRadioValues] = useState<radioModel>(
    placementRadioValuesDefaultValue,
  );
  const [previousEnrolRadioValues, setPreviousEnrolRadioValues] =
    useState<prevRadioModel>(previousEnrolRadioValuesDefaultvalue);
  const [isBackNumberCheck, setIsBackNumberCheck] = useState<boolean | null>(
    null,
  );
  const [isName, setIsName] = useState(false);
  const [lookUpShow, setLookUpShow] = useState(false);
  const [backNumberShow, setBackNumberShow] = useState(true);
  const [nameShow, setNameShow] = useState(false);
  const [defaultDropDownValue, setDefaultDropDownValue] =
    useState<DropDownModel>({ id: 2, text: 'Back Number' });

  const [heightWeight, setHeightWeight] = useState<heightWeightModel>();
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const [backNumberData, setBackNumberData] = React.useState<
    string[] | undefined
  >([]);
  const [seasonValue, setSeasonValue] = useState<GeneralLookup>();
  const [teamValue, setTeamValue] = useState<GeneralLookup>();
  const [categoryValue, setCategoryValue] = useState<GeneralLookup>();
  const [categoryDefaultValue, setCategoryDefaultValue] =
    useState<GeneralLookup>();
  const [divisionValue, setDivisionChange] = useState<GeneralLookup>();
  const [checkedMap, setCheckedMap] = React.useState(null);

  const handleClick = (
    seasonId: string,
    searchBy: number = 1,
    backNumber: string,
    riderName: string,
    categoryId: string,
    divisionId: string,
    teamId: string,
  ) => {
    getRidersList(
      seasonId,
      backNumber,
      riderName,
      categoryId,
      divisionId,
      teamId,
    );
    setShowTable(true);
    setCheckedMap(null);
    setIsChecked(true);
  };
  const handleBackClick = () => {
    setShowTable(false);
  };
  const handleNxt = () => {
    const seasonIdPayload = seasonValue?.id! ? seasonValue?.id! : seasonId!;
    getRiderInfo(riderId!, seasonIdPayload);
    Cookies.set('riderId', riderId);
  };
  const handleCheckboxClick = riderId => {
    if (isChecked === riderId) {
      setCheckedMap(null);
    } else {
      setCheckedMap(riderId);
      setIsChecked(false);
    }
    setRiderId(riderId);
  };

  const handlePlacementSubmit = () => {
    const updatedRiderData = {
      ...riderData,
      riderAssociations: riderData.riderAssociations?.filter(
        item => item !== undefined && item !== null,
      ),
    };
    dispatch(PageActions.setPageLoader(true));
    handleRequest(riderAddNewRider(updatedRiderData))
      .then(res => {
        if (res && !isApiError(res)) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          if (photoUploadPayload?.formData?.file !== undefined) {
            handlePhotoUpload(res.toString(), photoUploadPayload.formData);
          }
          dispatch(manageAppActions.setNewRiderData(riderData));
          dispatch(manageAppActions.setPreviousValue(previousData));
          dispatch(
            manageAppActions.setPreviousEnrolRadioValues(
              previousEnrolRadioValues,
            ),
          );
          dispatch(
            manageAppActions.setPlacementRadioValues(placementRadioValues),
          );
          dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
          dispatch(PageActions.setPageLoader(false));
          Cookies.set('previousEnrolData', JSON.stringify(previousData));
          dispatch(
            ErrorHandlingActions.dispatchToast({
              errorTxt: 'Rider registration successfully',
              show: true,
              errorType: 'success',
            }),
          );
          navigate(`/payment/${payerTypes?.id}/${res}/false/false`);
        } else {
          dispatch(
            ErrorHandlingActions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
        }
      })
      .catch(error => {
        setError(apiError);
        dispatch(
          ErrorHandlingActions.dispatchToast({
            errorTxt: error.description,
            show: true,
            errorType: 'error',
          }),
        );
        dispatch(PageActions.setPageLoader(false));
      });
  };
  const handlePhotoUpload = (
    riderId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(riderUploadRiderPhoto(riderId, formData))
      .then(res => {})
      .catch(error => {});
  };

  const handleBackNumberChange = e => {
    const inputValue = e.target.value;
    if (inputValue?.length <= 4 && /^\d*$/.test(inputValue)) {
      setBackNumber(inputValue);
    }
    if (inputValue?.length >= 3) {
      setIsBackNumberCheck(false);
    } else {
      setIsBackNumberCheck(true);
    }
  };

  const selectSreachedby = e => {
    setIsChecked(true);
    setDefaultDropDownValue(e);
    setShowTable(false);
    setTeamValue(teamValueDefault);
    setCategoryValue(teamValueDefault);
    setDivisionChange(teamValueDefault);
    setBackNumber('');
    setNameChange('');
    if (e.id === 1) {
      setLookUpShow(true);
      setBackNumberShow(false);
      setNameShow(false);
      setIsName(false);
    } else if (e.id === 2) {
      setBackNumberShow(true);
      setLookUpShow(false);
      setNameShow(false);
      setIsName(false);
    } else {
      setNameShow(true);
      setLookUpShow(false);
      setBackNumberShow(false);
      setIsName(true);
    }
  };
  const seasonChange = e => {
    setSeasonValue(e);
    setCategoryDatas(e.children);
    setTeamValue(lookipCategoryDefaultValue);
    setCategoryValue(lookipCategoryDefaultValue);
    setDivisionChange(lookipCategoryDefaultValue);
    setBackNumber('');
    setNameChange('');
  };
  const teamChangeData = e => {
    setTeamValue(e);
  };
  const onDivisionChange = e => {
    setDivisionChange(e);
  };
  const onCategoryChange = e => {
    setCategoryValue(e);
    setCategoryDefaultValue(e);
    setDivisionDatas(e.children);
  };

  useEffect(() => {
    if (riderSearchList) {
      setRidertableData(riderSearchList);
    }
  }, [riderSearchList]);

  useEffect(() => {
    setBackNumberData(riderBackNumberList);
  }, [riderBackNumberList]);
  useEffect(() => {
    if (riderDetails?.id) {
      setRiderData(riderDetails);
      setShowInfo(!showInfo);
      const filteredPrimaryPhone = riderDetails?.primaryPhone
        ? riderDetails?.primaryPhone.replaceAll('-', '')
        : '';
      const filteredParentPhone = riderDetails?.parent?.phone
        ? riderDetails?.parent?.phone?.replaceAll('-', '')
        : '';
      setRiderData({
        ...riderData,
        id: null,
        riderIdOld: riderDetails?.riderIdOld,
        isReturningRider: true,
        backNumber: riderDetails.backNumber ?? '',
        firstName: riderDetails.firstName ?? '',
        lastName: riderDetails.lastName?.replaceAll('-', '') ?? '',
        currentGrade: riderDetails.currentGrade,
        expectedGraduationYear: riderDetails.expectedGraduationYear ?? 0,
        dateOfBirth: riderDetails.dateOfBirth ?? '',
        height: riderDetails.height ?? 0,
        weight: riderDetails.weight ?? 0,
        primaryPhone: filteredPrimaryPhone,
        email: riderDetails.email ?? '',
        street: riderDetails.street ?? '',
        city: riderDetails.city ?? '',
        zip: riderDetails.zip ?? '',
        state: riderDetails?.state,
        team: defaultLookup,
        parent: {
          ...riderData.parent,
          firstName: riderDetails?.parent?.firstName?.replaceAll(' ', ''),
          lastName: riderDetails.parent?.lastName,
          city: riderDetails.parent?.city,
          zip: riderDetails.parent?.zip,
          email: riderDetails.parent?.email,
          phone: filteredParentPhone,
          street: riderDetails.parent?.street,
          state: riderDetails?.parent?.state,
        },
        school: {
          ...riderData.school,
          schoolName: riderDetails.school?.schoolName,
          street: riderDetails.school?.street,
          zip: riderDetails.school?.zip,
          state: riderDetails.school?.state,
          city: riderDetails.school?.city,
        },
        category: defaultLookup,
        divisionClassOne: defaultLookup,
        divisionClassTwo: defaultLookup,
        placementData: riderDetails.placementData,
        usePhotoForPromotion: riderDetails.usePhotoForPromotion,
      });
      setPreviousData({
        ...previousData,
        seasonList: riderDetails.season,
        category: riderDetails.category!,
        division: riderDetails.divisionClassOne!,
      });
      setPreviousEnrolRadioValues({
        hasStayedInSameDivisionForTwoYears:
          riderDetails.hasStayedInSameDivisionForTwoYears,
        achievedTopTwoInIndividualNationals:
          riderDetails.achievedTopTwoInIndividualNationals,
        achievedTopThreeInDivision: riderDetails.achievedTopThreeInDivision,
        divisionCompliesWithSectionThirty:
          riderDetails.divisionCompliesWithSectionThirty,
      });
      setHeightWeight({
        feet: Math.floor(riderDetails?.height!)
          ? Math.floor(riderDetails?.height!)
          : undefined,

        inches: Math.round(
          (riderDetails?.height! - Math.floor(riderDetails?.height!)) * 12,
        )
          ? Math.round(
              (riderDetails?.height! - Math.floor(riderDetails?.height!)) * 12,
            )
          : undefined,

        weight: riderDetails.weight ? riderDetails.weight : undefined,
      });
      setPlacementRadioValues({
        pwhQuestionOne: riderDetails?.placementData?.pwhQuestionOne,
        owhQuestionOne: riderDetails?.placementData?.owhQuestionOne,
        ewhQuestionOne: riderDetails?.placementData?.ewhQuestionOne,
        rwhQuestionOne: riderDetails?.placementData?.rwhQuestionOne,
        swhQuestionOne: riderDetails?.placementData?.swhQuestionOne,
        swhQuestionTwo: riderDetails?.placementData?.swhQuestionTwo,
        dwhQuestionOne: riderDetails?.placementData?.dwhQuestionOne,
        drQuestionOne: riderDetails?.placementData?.drQuestionOne,
        drQuestionTwo: riderDetails?.placementData?.drQuestionTwo,
        usePhotoForPromotion: riderDetails?.usePhotoForPromotion,
      });
    }
  }, [riderDetails]);

  useEffect(() => {
    if (lookUps) {
      lookUps
        .find(obj => {
          return obj.type === 'SEASON';
        })
        ?.values?.find(item => {
          if (item.isAdminLive === true) {
            return setSeasonId(item?.id!);
          }
        });
      lookUps
        .find(obj => {
          return obj.type === 'SEASON';
        })
        ?.values?.find(item => {
          if (item.isAdminLive === true) {
            return setCategoryDefaultDatas(item?.children!);
          }
        });
      let varPayerTypes = lookUps?.filter(item => {
        if (item?.type === 'PAYER_TYPES') {
          return item.values;
        }
      });
      let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
        if (item?.name === 'Rider') {
          return item ?? {};
        }
      });
      const seasonValues = lookUps?.find(
        item => item.type === 'SEASON',
      )?.values;
      const isAdminLiveFiltered = seasonValues?.find(item => item?.isAdminLive);
      let varDivison = lookUps?.filter(item => {
        if (item?.type === 'DIVISION') {
          return item.values;
        }
      });
      let filteredDIvison = varDivison?.map(item =>
        item.values?.map(data => data.name),
      );
      let seasonData =
        lookUps.find(obj => {
          return obj.type === 'SEASON';
        })?.values ?? [];
      const filteredSeasonData = seasonData.filter(season => {
        const currentYear = new Date().getFullYear();
        const seasonYear = parseInt(season.name.split('-')[1]);
        return seasonYear <= currentYear + 1;
      });
      setPayerTypes(payerTypeObj);
      setSeasonDivision(filteredDIvison);
      setRiderData(prev => {
        return {
          ...prev,
          season: isAdminLiveFiltered!,
        };
      });
      setFilteredSeasonLookupData(filteredSeasonData);
      dispatch(PageActions.setPageLoader(false));
    }
  }, [lookUps]);

  useEffect(() => {
    getAllLookupData(
      'DIVISION,CATEGORY,ACTIVETEAM,STATE,SEASON,PAYER_TYPES,GRADE,PROFESSIONALAFFILIATIONS,ACTIVETEAM',
    );
    getRiderBackNumberList();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
  }, []);

  useEffect(() => {
    if (cancelPaymentCookie && newRiderData) {
      setShowInfo(true);
      setPreviousData(newRiderPreviousEnrolData!);
      setRiderData(newRiderData);
      setValues(6);
      setPlacementRadioValues(placementRadioDataRedux!);
      setPreviousEnrolRadioValues(previousEnrolRadioDataRedux!);
      setHeightWeight({
        feet: Math.floor(newRiderData?.height!),
        inches: Math.round(
          (newRiderData?.height! - Math.floor(newRiderData?.height!)) * 12,
        ),
        weight: newRiderData.weight,
      });
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
    }
  }, [cancelPaymentCookie]);
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper className="page-content-wrapper">
          {showInfo ? (
            <RiderInformation
              riderData={riderData}
              setRiderData={setRiderData}
              handlePlacementSubmit={handlePlacementSubmit}
              lookUps={lookUps}
              teamFilteredCategory={teamFilteredCategory}
              setValues={setValues}
              values={values}
              previousData={previousData}
              setRiderDivisionRes={setRiderDivisionRes}
              setIsTermsChecked={setIsTermsChecked}
              isTermsChecked={isTermsChecked}
              placementRadioValues={placementRadioValues}
              setPlacementRadioValues={setPlacementRadioValues}
              previousEnrolRadioValues={previousEnrolRadioValues}
              setHeightWeight={setHeightWeight}
              heightWeight={heightWeight}
              setPhotoUploadPayload={setPhotoUploadPayload}
              photoUploadPayload={photoUploadPayload}
              setFileAvailability={setFileAvailability}
              fileAvailability={fileAvailability}
              setUploadFile={setUploadFile}
              uploadFile={uploadFile}
              backNumberData={backNumberData}
              setTeamFilteredCategory={setTeamFilteredCategory}
              riderDivisionRes={riderDivisionRes}
              seasonDivison={seasonDivison}
              teamLookup={teamLookup}
              getAllLookupByCategory={getAllLookupByCategory}
            />
          ) : (
            <>
              {loading ? (
                <LoaderWrapper>
                  <CircularLoader showText={true} />
                </LoaderWrapper>
              ) : (
                <FormMainWrapper className="form-MainWrapper">
                  <p className="Team-Heading">Returning Rider</p>{' '}
                  <p className="para-one">
                    <i>
                      Please enter the data from your previous year to retrieve
                      your account.
                    </i>
                  </p>
                  <OverflowWrapper className="OverflowWrapper">
                    <TextFieldGrid className="TextFieldGrid">
                      <TextFieldWrapper>
                        <SHdropdown
                          className="drop-down"
                          label="Season"
                          data={filteredSeasonLookupData}
                          defaultValue={
                            seasonValue
                              ? seasonValue
                              : lookUps
                                  .find(obj => {
                                    return obj.type === 'SEASON';
                                  })
                                  ?.values?.find(
                                    item => item.isAdminLive === true,
                                  ) ?? undefined
                          }
                          onDataChange={event => {
                            seasonChange(event);
                          }}
                        />
                      </TextFieldWrapper>
                      <TextFieldWrapper>
                        <SHdropdown
                          className="drop-down"
                          label="Searched by"
                          genericData={searchedBy}
                          genericDefaultValue={defaultDropDownValue}
                          onGeneralDataChange={event => {
                            selectSreachedby(event);
                          }}
                        />
                      </TextFieldWrapper>
                      {lookUpShow && (
                        <>
                          {' '}
                          <TextFieldWrapper>
                            <SHdropdown
                              className="drop-down"
                              label="Team"
                              data={
                                lookUps.find(obj => {
                                  return obj.type === 'ACTIVETEAM';
                                })?.values ?? []
                              }
                              onDataChange={event => {
                                teamChangeData(event);
                              }}
                              defaultValue={teamValue}
                            />
                          </TextFieldWrapper>
                          <TextFieldWrapper>
                            <SHdropdown
                              className="drop-down"
                              label="Category"
                              data={
                                categoryDatas
                                  ? categoryDatas
                                  : categoryDefaultDatas
                              }
                              onDataChange={event => {
                                onCategoryChange(event);
                              }}
                              defaultValue={categoryValue}
                            />
                          </TextFieldWrapper>
                          <TextFieldWrapper>
                            <SHdropdown
                              className="drop-down"
                              label="Division"
                              data={divisionDatas}
                              onDataChange={event => {
                                onDivisionChange(event);
                              }}
                              defaultValue={divisionValue}
                            />
                          </TextFieldWrapper>
                        </>
                      )}
                      {backNumberShow && (
                        <>
                          <TextFieldWrapper>
                            <StyledTextField
                              label="Back Number (3 or 4 digits)"
                              type="text"
                              name="backNumber"
                              value={backNumber}
                              onChange={handleBackNumberChange}
                              validations={'required|min:3|max:4'}
                              validator={simpleValidator.current}
                            />
                          </TextFieldWrapper>
                        </>
                      )}
                      {nameShow && (
                        <>
                          <TextFieldWrapper>
                            <StyledTextField
                              label="Name"
                              type="text"
                              name="name"
                              value={nameValue}
                              onChange={e => {
                                setNameChange(e.target.value);
                                setIsName(e.target.value.trim() === '');
                              }}
                              validations={'required|max:40'}
                              validator={simpleValidator.current}
                            />
                          </TextFieldWrapper>
                        </>
                      )}
                    </TextFieldGrid>
                    <SearchButtonWrapper>
                      <Button
                        className="main-btn-back"
                        onClick={() => {
                          navigate('/rider-registration-page');
                        }}
                      >
                        Back
                      </Button>
                      <SearchButton
                        onClick={() => {
                          handleClick(
                            seasonValue?.id! ? seasonValue?.id! : seasonId!,
                            defaultDropDownValue?.id,
                            backNumber,
                            nameValue,
                            categoryValue?.id!
                              ? categoryValue?.id!
                              : categoryDefaultValue?.id!,
                            divisionValue?.id!,
                            teamValue?.id!,
                          );
                        }}
                        disableNext={
                          (isBackNumberCheck !== null
                            ? isBackNumberCheck
                            : false) || isName
                        }
                      >
                        Search
                      </SearchButton>
                    </SearchButtonWrapper>
                    {showTable ? (
                      <TableButtonWrapper>
                        <RiderTable
                          riderTableData={
                            riderTableData?.length ? riderTableData : []
                          }
                          handleCheckboxClick={handleCheckboxClick}
                          checkedMap={checkedMap}
                        />
                        <InfoWrapper>
                          <p className="para-heading">
                            <i>PLEASE NOTE:</i>
                          </p>
                          <p className="para-content">
                            You are able to edit and update information in
                            Returning Rider Registration.
                          </p>
                        </InfoWrapper>
                        <ButtonWrapper className="bg-color-change">
                          <BackNextButton
                            classNameBack="btn-back"
                            classNameNxt="btn-nxt"
                            onClickNxt={handleNxt}
                            onClickBack={handleBackClick}
                            back="Cancel"
                            next="Next"
                            disableNext={isChecked}
                          />
                        </ButtonWrapper>
                      </TableButtonWrapper>
                    ) : (
                      ''
                    )}
                  </OverflowWrapper>
                </FormMainWrapper>
              )}
            </>
          )}
        </MainWrapper>
      )}
    </>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 97px);
  display: flex;
  position: relative;
  flex-direction: column;

  .para-one {
    color: #ff9900;
    font-size: 12px;
    border-bottom: 1px solid #5d5c5c;
    padding: 40px 0px 10px;
    margin-bottom: 30px;
  }
  label {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .Stepper-wrapper {
    .k-stepper {
      min-height: 75px;
    }
  }
  //Responsive

  @media (min-width: 100px) and (max-width: 319px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        gap: 5px;
      }
      .circular-stepper-heading {
        font-size: 11px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 50px;
      }
    }

    .form-MainWrapper {
      padding: 10px 2px 0px 2px;

      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .OverflowWrapper {
        padding: 10px 2px 100px 2px;
      }
      .TextFieldWrapper {
        width: 100%;
        .drop-down {
          margin: 0px;
        }
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        .StyledTextField {
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0px;

        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0px;
      }
      .StyledTextField {
        margin: 0px 0px;
      }
      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 11px !important;
        }
      }
      .TextFieldGrid {
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .StyledHeightTextField {
        margin: 0px 0px 0px 20px !important;
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .StyledWeightTextField {
        margin: 0px !important;
      }
      .MainAddressWrapper {
        margin: 0px !important;
        padding: 0px 0px 85px 0px !important;
        .text-left-NoteField {
          width: 100% !important;
          .note-block {
            font-size: 11px;
            letter-spacing: 0.1px;
          }
        }
      }
      .flex-NoteWrapper {
        margin: 0px;
        .note-block {
          font-size: 11px;
        }
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledFormSecondaryLabel {
        font-size: 10px !important;
        letter-spacing: 0.2px;
      }
      .QuestionWrapper {
        .FormSecondaryLabel {
          font-size: 10px !important;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 12px;
      }
      .circular-stepper-heading {
        font-size: 16px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 55px;
      }
    }

    .form-MainWrapper {
      padding: 10px 5px 0px 5px;

      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .OverflowWrapper {
        padding: 20px 6px 100px 6px;
      }
      .TextFieldWrapper {
        width: 100%;
        .drop-down {
          margin: 0px;
        }
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .StyledTextField {
          margin-left: 0px;
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0px;
      }
      .StyledTextField {
        margin: 0px 0px;
      }

      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 13px !important;
        }
      }
      .TextFieldGrid {
        padding: 10px 0px !important;
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .MainAddressWrapper {
        margin: 0px !important;
        padding: 0px 0px 85px 0px !important;
        .text-left-NoteField {
          width: 100% !important;
          .note-block {
            font-size: 11px;
            letter-spacing: 0.1px;
          }
        }
      }

      .NoteFieldWrapper {
        margin: 0px !important;
        .note-block {
          font-size: 10px;
          letter-spacing: 0.2px;
        }
      }
      .StyledWeightTextField {
        margin: 0px !important;
      }

      .StyledHeightTextField {
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .horizontal-line {
        margin: 20px 0px 15px 0px;
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledFormSecondaryLabel {
        font-size: 10px !important;
        letter-spacing: 0.2px;
      }
      .QuestionWrapper {
        .FormSecondaryLabel {
          font-size: 10px !important;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 22px;
      }
      .circular-stepper-heading {
        font-size: 22px;
      }
    }

    .form-MainWrapper {
      padding: 10px 10px 0px 10px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .OverflowWrapper {
        padding: 20px 10px 100px 10px;
      }

      #InfoLabelWrapper {
        label {
          font-size: 10px !important;
        }
      }
      .TextFieldWrapper {
        width: 100%;
        &.height-weight-wrapper {
          .note-block {
            font-size: 8px !important;
            padding-top: 22px !important;
          }
        }
        .drop-down {
          margin: 0 8px;
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
          padding: 0 8px;
        }
        .StyledTextField {
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0 8px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0 8px;
      }
      .StyledTextField {
        margin: 0px 8px;
      }
      .note-block {
        font-size: 10px;
      }
      #BottomRightWrapper {
        height: 50px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .form-MainWrapper {
      padding: 10px 10px 0px 10px;
      .OverflowWrapper {
        padding: 20px 0px 100px 0px;
      }
      .TextFieldWrapper {
        width: 100%;
        &.height-weight-wrapper {
          .note-block {
            font-size: 8px !important;
            padding-top: 22px !important;
          }
        }
        .drop-down {
          margin: 0 8px;
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
          padding: 0 8px;
        }
        .StyledTextField {
        }
      }
      .current-grade {
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0 8px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0 8px;
      }
      .StyledTextField {
        margin: 0px 8px;
      }
      .note-block {
        font-size: 10px;
      }
      #BottomRightWrapper {
        height: 50px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .form-MainWrapper {
      .BackFieldWrapper {
        width: 50%;
        padding: 0px;
        .BackNumberWrapper {
        }
        .StyledTextField {
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
  }
`;
const FormMainWrapper = styled.div`
  padding: 20px 60px 100px 60px;
  height: 100%;
  .Team-Heading {
    margin: 0px;
    padding: 0px !important;
    text-align: center;
    color: #9597a6;
  }
`;
const TextFieldWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  color: #9597a6;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    width: 100% !important;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;
const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    position: absolute;
    top: 60%;
  }
  span {
    color: #9597a6;
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: calc(100vh - 250px);
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;

const TableButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .k-grid-content {
    ::-webkit-scrollbar {
      width: 2px;
      margin-left: 3px;
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      background: transparent !important;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      width: 2px;
      background: #201e1e !important;
      border-radius: 10px;
    }
  }
`;
const SearchButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 30px;
  .main-btn-back {
    background-color: #2a2a2a;
    border: 1px solid #606060;
    color: #909090;
    height: 35px;
    &.light {
      background-color: #ededed;
    }
    &.dark {
      background-color: #2a2a2a;
    }
  }
  button {
    width: 100px;
    height: 30px;
    margin: 0px 10px;
    border-radius: 20px;
    background: #5b5a5a;
    border: 1px solid #8b8b8b;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 10px;
  margin: 30px 0px 20px;
  padding: 2px 20px;
  background: #27292d;
  .para-heading {
    color: #ff9900;
    border-bottom: 1px solid #5d5c5c;
    font-size: 14px;
  }
  .para-content {
    font-family: 'Roboto';
    color: #a7a7a7;
    font-size: 12px;
    padding: 10px 0px;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    .para-content {
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .para-content {
      font-size: 10px;
    }
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    .para-content {
      font-size: 11px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1719px) {
    .para-content {
      font-size: 12px;
    }
  }

  @media (min-width: 1720px) and (max-width: 4000px) {
    .para-content {
      font-size: 13px;
    }
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -25px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
export default ReturningRider;
