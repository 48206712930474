import { BackNextButton } from 'app/components/BackNextButton';
import React from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { useState } from 'react';
import SHdropdown from 'app/components/SHdropdown';
import {
  GeneralLookup,
  RegisterRider,
  RiderDivisionResponse,
} from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import Cookies from 'js-cookie';

type Props = {
  onClickBackEnrolment?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  tryItRegistration: RegisterRider;
  setTryItRegistration: React.Dispatch<React.SetStateAction<RegisterRider>>;
  onClickNxtEnrolment?: () => void;
  teamData?: GeneralLookup[];
  categoryData?: GeneralLookup[];
  teamDefault: GeneralLookup | null;
  setTeamDefault: React.Dispatch<React.SetStateAction<GeneralLookup | null>>;
  teamFilteredCategory: GeneralLookup[] | undefined;
  seasonDivison: (string[] | undefined)[] | undefined;
  riderDivisionRes: RiderDivisionResponse | undefined;
  setDatas: React.Dispatch<any>;
  datas: any;
  setTeamFilteredCategory: React.Dispatch<
    React.SetStateAction<GeneralLookup[] | undefined>
  >;
  teamLookup?: GeneralLookup[];
};

const NewMemberEnrolmentData = ({
  onClickBackEnrolment,
  tryItRegistration,
  setTryItRegistration,
  onClickNxtEnrolment,
  teamData,
  categoryData,
  teamDefault,
  setTeamDefault,
  teamFilteredCategory,
  seasonDivison,
  riderDivisionRes,
  setDatas,
  datas,
  setTeamFilteredCategory,
  teamLookup,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(false);
  const currentSeason = Cookies.get('isAdminLiveFiltered');
  let divisionDefaultValue = tryItRegistration.divisionClassOne;
  let divisionTwoDefaultValue = tryItRegistration.divisionClassTwo;
  React.useEffect(() => {
    setDivisionOneDefault(divisionDefaultValue);
    setDivisionTwoDefault(divisionTwoDefaultValue);
  }, [divisionDefaultValue, divisionTwoDefaultValue]);

  const [divisionOneDefault, setDivisionOneDefault] = React.useState<
    GeneralLookup | undefined
  >(tryItRegistration?.divisionClassOne);
  const [divisionTwoDefault, setDivisionTwoDefault] = React.useState<
    GeneralLookup | undefined
  >(tryItRegistration?.divisionClassTwo);
  const [filteredDivisonTwo, setFilteredDivisonTwo] = useState<GeneralLookup[]>(
    [],
  );
  const [isDivisionOnevalue, setIsDivisionOnevalue] = useState(true);

  const handleCategorydataChange = (data: GeneralLookup) => {
    if (tryItRegistration) {
      setTryItRegistration({
        ...tryItRegistration,
        category: data,
        divisionClassOne: { id: '', name: '' },
        divisionClassTwo: undefined,
      });
      setDatas(prevDatas => {
        const filteredData = categoryData?.filter(
          item => item?.name === data.name,
        );
        const filtered = filteredData?.[0]?.children || [];

        if (riderDivisionRes?.division?.name === 'Opal') {
          return filtered;
        } else if (riderDivisionRes?.division?.name === 'Emerald') {
          return filtered.filter(gem => !gem.name.includes('Opal'));
        } else if (riderDivisionRes?.division?.name === 'Ruby') {
          return filtered.filter(
            gem => !gem.name.includes('Opal') && !gem.name.includes('Emerald'),
          );
        } else if (riderDivisionRes?.division?.name === 'Sapphire') {
          return filtered?.filter(
            gem =>
              !gem.name.includes('Opal') &&
              !gem.name.includes('Emerald') &&
              !gem.name.includes('Ruby'),
          );
        } else if (riderDivisionRes?.division?.name === 'Diamond') {
          return filtered?.filter(
            gem =>
              !gem.name.includes('Opal') &&
              !gem.name.includes('Emerald') &&
              !gem.name.includes('Ruby') &&
              !gem.name.includes('Sapphire'),
          );
        } else if (riderDivisionRes?.division?.name === 'Pearl') {
          return filtered?.filter(gem => gem.name.includes('Pearl'));
        }
        return prevDatas;
      });
    }
  };

  const handleTeamChange = data => {
    if (data) {
      if (data.children !== null) {
        setTeamFilteredCategory(
          data?.children.filter(
            item =>
              riderDivisionRes?.category?.name.toLowerCase() ===
              item?.name.toLowerCase(),
          ),
        );
        setTryItRegistration?.(prevData => ({
          ...prevData,
          team: data,
          category:
            data?.children.filter(
              item =>
                riderDivisionRes?.category?.name.toLowerCase() ===
                item?.name.toLowerCase(),
            )[0] ?? [],
          divisionClassOne: {
            id: '',
            name: '',
            code: null,
            children: [],
            otherName: null,
            isAdminLive: false,
          },
          divisionClassTwo: {
            id: '',
            name: '',
            code: null,
            children: [],
            otherName: null,
            isAdminLive: false,
          },
        }));
        setDatas(prevDatas => {
          const filteredData = categoryData?.filter(
            item =>
              item?.name ===
              data?.children.filter(
                item =>
                  riderDivisionRes?.category?.name.toLowerCase() ===
                  item?.name.toLowerCase(),
              )[0]?.name,
          );
          const filtered = filteredData?.[0]?.children || [];

          if (riderDivisionRes?.division?.name === 'Opal') {
            return filtered;
          } else if (riderDivisionRes?.division?.name === 'Emerald') {
            return filtered.filter(gem => !gem.name.includes('Opal'));
          } else if (riderDivisionRes?.division?.name === 'Ruby') {
            return filtered.filter(
              gem =>
                !gem.name.includes('Opal') && !gem.name.includes('Emerald'),
            );
          } else if (riderDivisionRes?.division?.name === 'Sapphire') {
            return filtered?.filter(
              gem =>
                !gem.name.includes('Opal') &&
                !gem.name.includes('Emerald') &&
                !gem.name.includes('Ruby'),
            );
          } else if (riderDivisionRes?.division?.name === 'Diamond') {
            return filtered?.filter(
              gem =>
                !gem.name.includes('Opal') &&
                !gem.name.includes('Emerald') &&
                !gem.name.includes('Ruby') &&
                !gem.name.includes('Sapphire'),
            );
          } else if (riderDivisionRes?.division?.name === 'Pearl') {
            return filtered?.filter(gem => gem.name.includes('Pearl'));
          }
          return prevDatas;
        });
      }
      setTeamDefault(data);
    }
  };
  const handleDivisionOne = e => {
    const selectedGem =
      seasonDivison &&
      seasonDivison[0]?.filter(item => {
        return e.name?.includes(item);
      });
    const filteredDivisonArray = datas?.filter(item =>
      item.name.includes(selectedGem),
    );
    const divisonTwofiltered = filteredDivisonArray?.filter(item => item !== e);
    console.log(filteredDivisonArray, 'seasonClass88', seasonDivison);
    setTryItRegistration({
      ...tryItRegistration,
      divisionClassOne: e,
      divisionClassTwo: undefined,
    });
    setFilteredDivisonTwo(divisonTwofiltered);

    if (e) {
      setIsDivisionOnevalue(false);
    } else {
      setIsDivisionOnevalue(true);
    }
  };
  const handleDivisionTwo = e => {
    setTryItRegistration({
      ...tryItRegistration,
      divisionClassTwo: e,
    });
  };

  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [
    teamDefault,
    divisionOneDefault,
    tryItRegistration?.category,
    divisionTwoDefault,
  ]);
  return (
    <MainWrapper className="form-MainWrapper">
      <OverflowWrapper className="OverflowWrapper">
        <p className="enrolment-data">
          Your new year (
          {currentSeason === undefined ? '2023 - 2024' : currentSeason})
          enrolment data
        </p>
        <p className="proceed-para">
          * In order to proceed, you need to choose a team name to unlock the
          category selection. After selecting a category, you can then choose
          Division 1. Following this, you will have the option to choose
          Division 2, which is optional.
        </p>
        <TextFieldGrid>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Team Name"
              data={teamLookup}
              onDataChange={handleTeamChange}
              defaultValue={teamDefault ?? { id: '', name: '' }}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Category"
              data={teamFilteredCategory}
              onDataChange={data => handleCategorydataChange(data)}
              defaultValue={tryItRegistration?.category}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Division 1"
              data={datas}
              onDataChange={handleDivisionOne}
              defaultValue={divisionOneDefault}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Division 2"
              data={filteredDivisonTwo}
              onDataChange={handleDivisionTwo}
              defaultValue={divisionTwoDefault}
              // disabled={isDivisionOnevalue}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
      </OverflowWrapper>
      <ButtonWrapper className="bg-color-change">
        <BackNextButton
          classNameBack="btn-back"
          classNameNxt="btn-nxt"
          onClickNxt={() => onClickNxtEnrolment?.()}
          onClickBack={onClickBackEnrolment}
          next="Next"
          back="Back"
          disableNext={valid}
        />
      </ButtonWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 230px);
  padding: 20px 0px 0px;
  position: relative;
  .bottom-para {
    font-size: 12px;
    position: absolute;
    z-index: 3;
    position: absolute;
    bottom: 50px;
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 9px;
      bottom: 0px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 10px;
      bottom: 55px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 11px;
      bottom: 40px;
    }
    @media (min-width: 1200px) and (max-width: 1365px) {
      font-size: 12px;
      bottom: 60px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      font-size: 12px;
      bottom: 65px;
    }
    @media (min-width: 1449px) and (max-width: 1719px) {
      font-size: 13px;
      bottom: 65px;
    }
    @media (min-width: 1720px) and (max-width: 4000px) {
      font-size: 14px;
      bottom: 70px;
    }
  }
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
  padding: 20px 60px 100px 60px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
  .enrolment-data,
  .proceed-para {
    margin: 12px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
const TextFieldWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  position: absolute;
  bottom: -2px;
  left: 0px;
  background: linear-gradient(
    to bottom,
    rgb(36 36 36 / 27%) 0%,
    rgb(42 42 42) 100%
  );
  border: 1px solid rgb(42 42 42);
  border-radius: 0 0 20px 20px;
  backdrop-filter: blur(5px);
  z-index: 2;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
    bottom: -20px;
  }
`;

export default NewMemberEnrolmentData;
