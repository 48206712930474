import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import SHdropdown from 'app/components/SHdropdown';
import { TextField } from 'app/components/TextField';
import { BackNextButton } from 'app/components/BackNextButton';
import { Alumni, GeneralLookup, GeneralLookupWrapper } from 'services/openapi';
import BackNumberTableComponent from 'app/components/BackNumberListComponent';
import TeamListPopUp from 'app/components/TeamListPopUp';
import SimpleReactValidator from 'simple-react-validator';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import SHBackNumberDropdown from 'app/components/SHBackNumberDropdown';
import InfoIconButton from 'app/components/InfoIconButton';

type Props = {
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  aluminiTryitRiderData: Alumni;
  setAluminiTryitRiderData: React.Dispatch<React.SetStateAction<Alumni>>;
  lookUp?: GeneralLookupWrapper[];
  setIsBackNumberCheck: React.Dispatch<React.SetStateAction<boolean | null>>;
  isBackNumberCheck: boolean | null;
  setHeightWeight: React.Dispatch<React.SetStateAction<heightWeightModel>>;
  heightWeight: heightWeightModel;
  backNumberData: string[] | undefined;
  teamLookup?: GeneralLookup[];
};

export const PersonalInfoForm = ({
  handleNextForm,
  handleBackForm,
  aluminiTryitRiderData,
  setAluminiTryitRiderData,
  lookUp,
  isBackNumberCheck,
  setIsBackNumberCheck,
  setHeightWeight,
  heightWeight,
  backNumberData,
  teamLookup,
}: Props) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [backNumberVisible, setBackNumberVisible] =
    React.useState<boolean>(false);

  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(false);

  const handleOpenBackNumberPopUp = () => {
    setBackNumberVisible(!backNumberVisible);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setAluminiTryitRiderData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    if (isBackNumberCheck === false) {
      setIsBackNumberCheck(null);
    }
  };
  const handleCloseTeamList = () => {
    setVisible(false);
  };
  const handleFeetChange = event => {
    const newFeet = parseInt(event.target.value);
    if (event.target.value && newFeet >= 0 && newFeet <= 9) {
      setHeightWeight(prevData => ({ ...prevData, feet: Math.floor(newFeet) }));
      const newHeightInch = newFeet + heightWeight.inches! / 12;
      if (aluminiTryitRiderData) {
        setAluminiTryitRiderData({
          ...aluminiTryitRiderData,
          height: newFeet,
        });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({
        ...prevData,
        feet: undefined,
      }));
    }
  };
  const handleInchesChange = event => {
    const newInches = parseInt(event.target.value);
    if (event.target.value && newInches >= 0 && newInches <= 11) {
      setHeightWeight(prevData => ({
        ...prevData,
        inches: Math.floor(newInches),
      }));
      const newHeightInch = Number(heightWeight.feet!) + newInches / 12;
      if (aluminiTryitRiderData) {
        setAluminiTryitRiderData({
          ...aluminiTryitRiderData,
          height: newHeightInch,
        });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({
        ...prevData,
        inches: undefined,
      }));
    }
  };
  const handleWeightChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 3 && /^[0-9]*$/.test(inputValue)) {
      setAluminiTryitRiderData({
        ...aluminiTryitRiderData,
        weight: inputValue,
      });
      setHeightWeight(prevData => ({
        ...prevData,
        weight: inputValue,
      }));
    }
  };
  const handleBackNumberChange = name => {
    if (aluminiTryitRiderData) {
      setAluminiTryitRiderData?.({
        ...aluminiTryitRiderData,
        backNumber: name.value,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setAluminiTryitRiderData({
        ...aluminiTryitRiderData,
        primaryPhone: inputValue,
      });
    }
  };
  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setAluminiTryitRiderData({
        ...aluminiTryitRiderData,
        emergencyContactPhone: inputValue,
      });
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setAluminiTryitRiderData({
        ...aluminiTryitRiderData,
        zip: inputValue,
      });
    }
  };
  const handleTeamChange = data => {
    if (data) {
      setAluminiTryitRiderData({
        ...aluminiTryitRiderData,
        team: data,
      });
    }
  };
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [aluminiTryitRiderData]);
  return (
    <MainWrapper id="PersonalInfoFormMainWrapper" className="form-MainWrapper">
      <SubMainWrapper className="SubMainWrapper">
        <BorderWrapper className="inner-BorderWrapper">
          <TextFieldGrid className="TextFieldGrid">
            <TextFieldWrapper className="TextFieldWrapper ">
              <StyledTextField
                onChange={handleChange}
                value={aluminiTryitRiderData?.firstName}
                label="First Name"
                type="text"
                name="firstName"
                validations={'required|max:30'}
                validator={simpleValidator.current}
                className="StyledTextField"
              />
            </TextFieldWrapper>
            <TextFieldWrapper className="TextFieldWrapper ">
              <StyledTextField
                onChange={handleChange}
                value={aluminiTryitRiderData?.lastName}
                label="Last Name"
                type="text"
                name="lastName"
                validations={'required|max:30'}
                validator={simpleValidator.current}
                className="StyledTextField"
              />
            </TextFieldWrapper>{' '}
            <TextFieldWrapper className="TextFieldWrapper BackFieldMainWrapper">
              <BackFieldWrapper className="BackFieldWrapper">
                <SHBackNumberDropdown
                  onChange={e => handleBackNumberChange(e.value)}
                  data={backNumberData}
                  defaultValue={aluminiTryitRiderData?.backNumber!}
                  label="Back Number (2 digits)"
                  validations={'required|max:30'}
                  validator={simpleValidator.current}
                  isAbove={false}
                  className="BackfieldNumber"
                />
                <InfoIconButton
                  onClick={handleOpenBackNumberPopUp}
                  className="info-icon"
                />
              </BackFieldWrapper>
              {backNumberVisible && (
                <BackNumberTableComponent
                  onClose={handleOpenBackNumberPopUp}
                  backNumberData={backNumberData}
                  isAbove={false}
                />
              )}{' '}
            </TextFieldWrapper>
            <TextFieldWrapper className="TextFieldWrapper ">
              <SHdropdown
                label="Team Name"
                onDataChange={handleTeamChange}
                data={teamLookup}
                defaultValue={aluminiTryitRiderData?.team}
                validations={'required'}
                validator={simpleValidator.current}
                className="drop-down"
              />
            </TextFieldWrapper>
          </TextFieldGrid>
          <MainAddressWrapper>
            <div className="horizontal-line">
              <span>Coach Address</span>
            </div>
            <TextFieldGrid className="TextFieldGrid">
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleChange}
                  value={aluminiTryitRiderData?.street}
                  label=" Address"
                  type="text"
                  name="street"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleChange}
                  value={aluminiTryitRiderData?.city}
                  label=" City"
                  type="text"
                  name="city"
                  validations={'required|max:40'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <SHdropdown
                  label="State"
                  onDataChange={data => {
                    if (aluminiTryitRiderData) {
                      setAluminiTryitRiderData({
                        ...aluminiTryitRiderData,
                        state: data,
                      });
                    }
                  }}
                  data={lookUp?.find(obj => obj.type === 'STATE')?.values ?? []}
                  defaultValue={aluminiTryitRiderData?.state}
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleZipChange}
                  value={aluminiTryitRiderData?.zip}
                  label="Zip"
                  type="text"
                  name="zip"
                  validations={'required|alpha_num|min:5|max:9'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handlePhChange}
                  value={aluminiTryitRiderData?.primaryPhone}
                  label="Phone Number:"
                  type="tel"
                  name="primaryPhone"
                  validations={'required|numeric|min:10|max:10'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleChange}
                  value={aluminiTryitRiderData?.email}
                  label="Email:"
                  type="email"
                  name="email"
                  validations={'required|email'}
                  validator={simpleValidator.current}
                  maxLength={60}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleChange}
                  value={aluminiTryitRiderData?.emergencyContactName}
                  label="Emergency Contact Name"
                  type="text"
                  name="emergencyContactName"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleEmergencyPhChange}
                  value={aluminiTryitRiderData?.emergencyContactPhone}
                  label="PH:"
                  type="tel"
                  name="emergencyContactPhone"
                  validations={'required|numeric|min:10|max:10'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <NoteFieldWrapper className="NoteFieldWrapper">
                  <span className="note-block">
                    <span className="note-head">YEDA Back #:</span> Returning
                    Members wright your back number, new members leave blank a
                    number will be assigned to you. The $10 for the YEDA Back
                    Number go towards the YEDA Scholarship Incentive Fund.
                  </span>
                </NoteFieldWrapper>
              </TextFieldWrapper>

              <TextFieldWrapper className="TextFieldWrapper flex-TextFieldWrapper height-weight-wrapper">
                <FlexFieldWrapper className="FlexFieldWrapper">
                  <HeightFieldWrapper className="HeightFieldWrapper">
                    <StyledHeightTextField
                      onChange={handleFeetChange}
                      value={
                        heightWeight.feet === undefined ? '' : heightWeight.feet
                      }
                      label="Height"
                      type="number"
                      name="height"
                      placeholder="Feet"
                      validations={'required'}
                      className="StyledHeightTextField  StyledHeightTextField-first"
                      validator={simpleValidator.current}
                    />
                    <StyledHeightTextField
                      onChange={handleInchesChange}
                      value={
                        heightWeight.inches === undefined
                          ? ''
                          : heightWeight.inches
                      }
                      type="number"
                      name="inch"
                      label="Inches"
                      placeholder="inches"
                      className="StyledHeightTextField"
                    />
                    <NoteFieldWrapper className="NoteFieldWrapper flex-NoteWrapper">
                      <span className="note-block span-inch">
                        <span className="note-head"></span> eg. 5ft 10in
                      </span>
                    </NoteFieldWrapper>
                  </HeightFieldWrapper>
                  <StyledWeightTextField
                    onChange={handleWeightChange}
                    label="Weight(Lbs)"
                    value={
                      heightWeight.weight === undefined
                        ? ''
                        : heightWeight.weight
                    }
                    type="text"
                    name="weight"
                    placeholder="lbs"
                    validations={'required'}
                    validator={simpleValidator.current}
                    className="StyledWeightTextField"
                  />
                </FlexFieldWrapper>
              </TextFieldWrapper>
            </TextFieldGrid>
          </MainAddressWrapper>
        </BorderWrapper>
        {visible && <TeamListPopUp onClose={handleCloseTeamList} />}
      </SubMainWrapper>
      <BottomRightWrapper id="BottomRightWrapper">
        <ButtonWrapper>
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleNextForm}
            onClickBack={handleBackForm}
            disableNext={valid}
            next="Next"
            back="Back"
          />
        </ButtonWrapper>
      </BottomRightWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 10px;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const SubMainWrapper = styled.div`
  height: calc(100vh - 250px);
  width: 100%;
`;
const BorderWrapper = styled.div`
  height: 100%;
  overflow: auto;
  /* border: 1px solid #585858; */
  border-radius: 20px;
  padding: 20px 60px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    padding: 10px 5px 0px 5px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 10px 10px 0px;
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 0 20px 10px 20px;
  text-align: start;
  width: 100%;
  div {
    border-bottom: 1px solid #434343;
    padding: 6px 0;
    &.no-border {
      border: none !important ;
      label {
        font-style: normal;
      }
    }
  }
  h4 {
    margin: 20px 0 20px 0;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0 35px 0;

  /* gap: 15px; */
  @media (min-width: 992px) and (max-width: 1199px) {
    /* gap: 5px; */
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    /* gap: 10px; */
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    /* gap: 15px; */
  }
`;
const NoteFieldWrapper = styled.div`
  margin: 0px 20px;
  text-align: left;

  .span-inch {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .note-block {
    display: inline-grid;
    font-size: 12px;
    font-style: italic;
    .note-head {
      font-style: normal;
    }
  }
  &.flex-NoteWrapper {
    .note-block {
      display: inline-grid;
      font-size: 12px;
      padding-top: 15px;
      .note-head {
      }
    }
  }
`;
const TextFieldWrapper = styled.div`
  /* flex: 0 0 50%;
  margin-bottom: 20px;*/
  width: 50%;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }
  &.BackNumberFieldWrapper {
    width: 100% !important;
    .BackFieldWrapper {
      width: 50% !important;
    }
  }
`;
const FlexFieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;
const HeightFieldWrapper = styled.div`
  display: flex;
`;

const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
  input {
    width: 100%;
    &.back-number {
      &.green-border {
        border: 1px solid green;
      }
      &.red-border {
        border: 1px solid red;
      }
    }
  }
`;
const StyledHeightTextField = styled(TextField)`
  /* margin: 0px 0px 0px 20px !important; */
  input {
    width: 60px;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledWeightTextField = styled(TextField)`
  margin: 0px 20px 0px 0px;
  input {
    width: 100%;
    width: 120px;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const BackFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .BackfieldNumber {
    width: 80%;
    margin: 0px 12px;
  }
  .info-icon {
    margin: 24px 12px 0px 0px !important;
  }

  @media (min-width: 100px) and (max-width: 575px) {
    width: 100%;
    .info-icon {
      margin: 17px 0px 0px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    .info-icon {
      margin: 17px 12px 0px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 100%;
    .info-icon {
      margin: 20px 12px 0px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .info-icon {
      margin: 18px 12px 0px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .info-icon {
      margin: 21px 12px 0px !important;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .info-icon {
      margin: 23px 12px 0px !important;
    }
  }
`;

const MainAddressWrapper = styled.div`
  margin-bottom: 40px;
  width: 100%;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 12px 15px 12px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 0;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    /* position: absolute;
    bottom: 40px;
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(48 48 48 / 79%) 0%,
      rgb(42 42 42) 100%
    ); */
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
