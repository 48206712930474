import { GridLayoutItem } from '@progress/kendo-react-layout';
import { CustomgridLayoutWrapper } from 'admin/components/wrappers';
import { AdminTextField } from 'app/components/AdminTextField';
import React from 'react';
import styled from 'styled-components';
import AdminDropDown from 'app/components/AdminDropDown';
import { AdminRiderRegistration, GeneralLookupWrapper } from 'services/openapi';
import { getLookupArray } from 'utils/common';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  riderData: AdminRiderRegistration;
  setRiderdata: React.Dispatch<React.SetStateAction<AdminRiderRegistration>>;
  handleChange: (event: any) => void;
  lookUps: GeneralLookupWrapper[];
  simpleValidator: React.MutableRefObject<SimpleReactValidator>;
  riderDataLoading: boolean;
};

const ParentDetails = ({
  riderData,
  handleChange,
  setRiderdata,
  lookUps,
  simpleValidator,
  riderDataLoading,
}: Props) => {
  return (
    <>
      <GridWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[{ width: '31.6%' }, { width: '33%' }, { width: '33%' }]}
          gap={{ rows: 20, cols: 10 }}
        >
          <GridLayoutItem>
            <AdminTextField
              label="Parents First Name"
              placeholder=""
              value={riderData?.parent?.firstName ?? ''}
              onChange={handleChange}
              name="parent.firstName"
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Parents Last Name"
              placeholder=""
              value={riderData?.parent?.lastName ?? ''}
              onChange={handleChange}
              name="parent.lastName"
              className="field-style"
              hideStar
              isLoader={riderDataLoading}
              validations="max:100"
              validator={simpleValidator.current}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Parents Phone"
              placeholder=""
              value={riderData?.parent?.phone ?? ''}
              onChange={e => {
                const inputValue = e.target.value;
                if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
                  setRiderdata({
                    ...riderData,
                    parent: {
                      ...riderData.parent,
                      phone: inputValue,
                    },
                  });
                }
              }}
              name="parent.phone"
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Parents Email"
              placeholder=""
              value={riderData?.parent?.email ?? ''}
              onChange={handleChange}
              validations={'email|max:100'}
              validator={simpleValidator.current}
              name="parent.email"
              className="field-style"
              hideStar
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Parents Address"
              placeholder=""
              value={riderData?.parent?.street ?? ''}
              onChange={handleChange}
              name="parent.street"
              validations="required|max:300"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="City"
              placeholder=""
              value={riderData?.parent?.city ?? ''}
              onChange={handleChange}
              name="parent.city"
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              label="State"
              placeholder=""
              data={getLookupArray('STATE', lookUps)}
              onDataChange={data => {
                setRiderdata({
                  ...riderData,
                  parent: {
                    ...riderData.parent,
                    state: data,
                  },
                });
              }}
              defaultValue={riderData?.parent?.state}
              validations="required|max:100"
              validator={simpleValidator.current}
              className="field-style parent-state"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Zip"
              placeholder=""
              value={riderData?.parent?.zip ?? ''}
              onChange={handleChange}
              name="parent.zip"
              validations={'required|max:10'}
              validator={simpleValidator.current}
              className="field-style"
              isLoader={riderDataLoading}
            />
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
      </GridWrapper>
    </>
  );
};

export default ParentDetails;
const GridWrapper = styled.div`
  margin-bottom: 20px;
  .parent-state {
    .red-star {
      padding-left: 3px;
    }
  }
  @media (min-width: 1899px) and (max-width: 4000px) {
    .k-grid-layout {
      gap: 20px 15px !important;
      grid-template-columns: 30% 33% 33% !important;
    }
  }
  @media (max-width: 991px) {
    .k-grid-layout {
      grid-template-columns: 100% !important;
    }
  }
`;
