import {
  CommonPageWrapper,
  CustomgridLayoutWrapper,
  HeadingWrapper,
} from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { LabelMedium, PageMainLabel } from 'app/components/SHLabel';
import BackButton from 'app/components/BackButton';
import styled from 'styled-components';
import AdminCustomButton from 'app/components/AdminCustomButton';
import SimpleReactValidator from 'simple-react-validator';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ClassList,
  GeneralLookup,
  GeneralLookupWrapper,
  RiderClass,
  RiderInfo,
  ShowRider,
} from 'services/openapi';
import useUserApi from 'admin/hooks/useUserApi';
import { useParams } from 'react-router-dom';
import { CommonLoader } from 'app/components/CommonLoader';
import {
  capitalizeFirstLetter,
  findObjectById,
  getLookupArray,
  isEmptyArray,
} from 'utils/common';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import {
  ShowRiderWrapper,
  breadCrumpitemsUser,
  defaulShowstRiderValue,
} from './staticDefaultData';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import AdminDropDown from 'app/components/AdminDropDown';
import { defaultLookup } from 'admin/pages/RiderIndividual/components/RiderCreatePage/staticDatas';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import closeIcon from '../../../../../../../src/admin/assets/icon/Close.svg';
import useShowApi from 'admin/hooks/useShowApi';
import useRiderApi from 'admin/hooks/useRiderApi';
import { CardSkeletonLoader } from 'app/components/CardSkeletonLoader';
import { AdminMultiSelectDropDown } from 'app/components/AdminMultiSelectDropDown';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import RiderNameClassCell from 'app/components/CustomDataGrid/Cells/RiderNameClassCell';
import RiderClassUpdateCell from 'app/components/CustomDataGrid/Cells/RiderClassUpdateCell';

export const CreateShowsRider = () => {
  const {
    GetRiderListInShows,
    riderDataList,
    SaveRiderInShow,
    isResponseRider,
    getShowRiderInfo,
    showRiderInfo,
    errorData,
  } = useShowApi();

  const [showRider, setShowRider] = useState<ShowRider>();
  const [riderClass, setRiderClass] = useState<Array<RiderClass>>();
  const [classList, setClassList] = React.useState<Array<ClassList>>();
  const [riderListInfo, setRiderListInfo] = useState<RiderInfo[]>([]);
  const [riderList, setRiderList] = useState<GeneralLookup[] | undefined>([]);

  const simpleValidator = useRef(new SimpleReactValidator());
  const { action, id: showId } = useParams();
  const showLookup = useMemo(() => {
    let showLookup: GeneralLookup = {
      id: showId ?? '',
      name: 'show',
      code: null,
      children: null,
      otherName: null,
      isAdminLive: null,
    };
    return showLookup;
  }, [showId]);
  const classLookup = useMemo(() => {
    let showLookup: GeneralLookup = {
      id: '568a7609-fbab-475b-9284-cea62deb6b7a',
      name: 'Elementary',
      code: null,
      children: null,
      otherName: null,
      isAdminLive: null,
    };
    return showLookup;
  }, [showId]);
  const { getUserProfileViewData, loading } = useUserApi();
  const [teamId, setSeamId] = useState<GeneralLookup>();
  const [catagoryId, setCatagoryId] = useState<GeneralLookup>();
  const [divisionId, setDivisionId] = useState<GeneralLookup>();

  const [categoryListFromTeam, setCategoryListFromTeam] = React.useState<
    Array<GeneralLookup>
  >([]);
  const [riderListShows, setRiderListShows] = React.useState<
    Array<GeneralLookup>
  >([]);
  const [divisionOneList, setDivisionOnelist] = React.useState<
    Array<GeneralLookup>
  >([]);
  const [isCategoryNotInTeam, setIsCategoryNotInTeam] = useState(false);
  const [showsRiderData, setShowsRiderData] = React.useState<ShowRiderWrapper>(
    defaulShowstRiderValue,
  );
  const [forceUpdate, setForceUpdate] = useState(false);
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();

  const clearRiderData = () => {
    setRiderListInfo([]);
    setRiderListShows([]);
    setRiderClass(prevState => {
      const currentState = prevState || [];
      return currentState.map(riderEntry => ({
        ...riderEntry,
        classList: [], // Reset classList to an empty array
      }));
    });
    setClassAChecked(prevState => {
      const newState = {};
      Object.keys(prevState).forEach(riderId => {
        newState[riderId] = false; // Set each rider's checkbox to false
      });
      return newState;
    });
    setClassBChecked(prevState => {
      const newState = {};
      Object.keys(prevState).forEach(riderId => {
        newState[riderId] = false; // Set each rider's checkbox to false
      });
      return newState;
    });
    setClassASplitNumber(prevState => {
      const newState = {};
      Object.keys(prevState).forEach(riderId => {
        newState[riderId] = false; // Set each rider's checkbox to false
      });
      return newState;
    });
    setClassBSplitNumber(prevState => {
      const newState = {};
      Object.keys(prevState).forEach(riderId => {
        newState[riderId] = false; // Set each rider's checkbox to false
      });
      return newState;
    });
  };

  const onDataChangeTeam = e => {
    setShowsRiderData({
      ...showsRiderData,
      team: e,
      category: defaultLookup,
      divisionClassOne: defaultLookup,
    });
    setSeamId(e);
    setRiderList([]);
    clearRiderData();
    let teamLookUpList = getLookupArray('ACTIVETEAM', lookUps);
    const matchingObject = findObjectById(teamLookUpList, e.id);
    if (matchingObject) {
      const { children } = matchingObject;
      setCategoryListFromTeam(children!);
      setDivisionOnelist([]);
    }
    setIsCategoryNotInTeam(e.children.length === 0 ? true : false);
    const arrays = ['Category', `Division/Classes One`, `Division/Classes Two`];
    e.children.length === 0 &&
      arrays.forEach(field => {
        simpleValidator.current.hideMessageFor(field);
        simpleValidator.current.visibleFields = [];
        setForceUpdate(prevState => !prevState);
      });
  };
  const onDataChangeCategory = e => {
    setShowsRiderData({
      ...showsRiderData,
      category: e,
      divisionClassOne: defaultLookup,
      rider: defaultLookup,
    });
    setCatagoryId(e);
    setRiderList([]);
    clearRiderData();
    let categoryLookUpList = getLookupArray('CATEGORY', lookUps);
    const matchingObject = findObjectById(categoryLookUpList, e.id);
    if (matchingObject) {
      const { children } = matchingObject;
      setDivisionOnelist(children!);
    }
  };

  const onDataChangeDivisionOne = e => {
    GetRiderListInShows(
      showsRiderData.team.id,
      showsRiderData.category.id,
      e?.id,
    );
    setShowsRiderData({
      ...showsRiderData,
      divisionClassOne: e,
    });
    setDivisionId(e);
    clearRiderData();
  };
  const handleDataRemove = (selectedList: any, selectedItem: any) => {
    const updatedRiderListInfo = riderListInfo?.filter(
      itemList => itemList?.riderId !== selectedItem.id,
    );
    const updatedRiderShow = riderListShows?.filter(
      itemList => itemList?.id !== selectedItem.id,
    );
    setRiderListInfo(updatedRiderListInfo);
    setRiderListShows(updatedRiderShow);
  };
  const onDataChangeRider = (selectedList: any, selectedItem: any) => {
    setShowsRiderData({
      ...showsRiderData,
      rider: selectedList,
    });
    getShowRiderInfo(selectedItem.id);
    setRiderListShows(() => [...riderListShows, selectedItem]);

    //
    setRiderClass(prevState => {
      const currentState = prevState || [];
      return [
        ...currentState,
        {
          rider: selectedItem,
          team: teamId,
          category: catagoryId,
          division: divisionId,
          // classList: [
          //   {
          //     class: classLookup,
          //     splitNumber: 'M',
          //   },
          // ],
        },
      ];
    });
  };

  useEffect(() => {
    const updateRiderListInfo = () => {
      if (!errorData || !errorData?.riders || !riderListInfo) return;

      const updatedRiderListInfo = riderListInfo.map(rider => {
        const errorRider = errorData?.riders?.find(
          er => er?.rider?.id === rider.riderId,
        );

        if (!errorRider) return rider;

        const updatedRider = { ...rider };

        if (updatedRider.divisionClassOne) {
          const classOne = errorRider?.classList?.find(
            c => c?.class?.id === updatedRider?.divisionClassOne?.id,
          );
          if (classOne) {
            updatedRider.divisionClassOne = {
              ...updatedRider.divisionClassOne,
              isRiderExsit: classOne.isRiderExsit,
            } as any;
          }
        }

        if (updatedRider.divisionClassTwo) {
          const classTwo = errorRider?.classList?.find(
            c => c?.class?.id === updatedRider?.divisionClassTwo?.id,
          );
          if (classTwo) {
            updatedRider.divisionClassTwo = {
              ...updatedRider.divisionClassTwo,
              isRiderExsit: classTwo.isRiderExsit,
            } as any;
          }
        }

        return updatedRider;
      });

      setRiderListInfo(updatedRiderListInfo);
    };

    updateRiderListInfo();
  }, [errorData]);

  const handleSaveClick = () => {
    const isValid = simpleValidator.current.allValid();
    if (isValid) {
      // SaveRiderInShow(
      //   {
      //     show: {
      //       id: showId ?? '',
      //       name: 'showId',
      //     },
      //     team: {
      //       id: teamId?.id!,
      //       name: teamId?.name!,
      //     },
      //     category: {
      //       id: catagoryId?.id!,
      //       name: catagoryId?.name!,
      //     },
      //     division: {
      //       id: divisionId?.id!,
      //       name: divisionId?.name!,
      //     },
      //     splitNumber: '',
      //     riders: riderListShows,
      //     class: {
      //       id: '568a7609-fbab-475b-9284-cea62deb6b7a',
      //       name: 'Elementary',
      //     },
      //   },
      //   showId,
      // );

      SaveRiderInShow(
        {
          show: showLookup,
          riders: riderClass,
        },
        showId,
      );
    } else {
      simpleValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  };
  const handleCancelClick = () => {
    setShowsRiderData(defaulShowstRiderValue);
    setCategoryListFromTeam([]);
    setDivisionOnelist([]);
    setRiderList([]);
    setIsCategoryNotInTeam(false);
    simpleValidator.current.hideMessages();
    simpleValidator.current.visibleFields = [];
    setForceUpdate(prevState => !prevState);
    clearRiderData();
  };
  const handleCloseClick = (riderId?: string) => {
    const updatedRiderList = riderListInfo?.filter(
      itemList => itemList?.riderId !== riderId,
    );
    const updatedRiderShow = riderListShows?.filter(
      itemList => itemList?.id !== riderId,
    );
    setRiderListShows(updatedRiderShow);
    setRiderListInfo(updatedRiderList);
  };

  React.useEffect(() => {
    if (action === 'update') {
      getUserProfileViewData(showId ?? '');
    }
  }, [showId, action]);
  React.useEffect(() => {
    getAllLookupData('CATEGORY,DIVISION,ACTIVETEAM');
  }, []);
  useEffect(() => {
    if (showRiderInfo) {
      setRiderListInfo(prevRiderListInfo => {
        const isDuplicate = prevRiderListInfo?.some(
          item => item?.riderId === showRiderInfo?.riderId,
        );
        if (isDuplicate) {
          return prevRiderListInfo;
        }
        return [...(prevRiderListInfo || []), showRiderInfo];
      });
    }
  }, [showRiderInfo]);
  useEffect(() => {
    if (riderDataList) {
      setRiderList(riderDataList);
    }
  }, [riderDataList]);
  const [classASplitNumber, setClassASplitNumber] = React.useState<{
    [key: string]: GeneralLookup;
  }>({});

  const [classBSplitNumber, setClassBSplitNumber] = React.useState<{
    [key: string]: GeneralLookup;
  }>({});
  const [classAChecked, setClassAChecked] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [classBChecked, setClassBChecked] = React.useState<{
    [key: string]: boolean;
  }>({});
  const onClassASplitNumberDataChange = (e, event, className) => {
    setRiderClass(prevState => {
      const currentState = prevState || [];
      return currentState.map(riderEntry => {
        if (riderEntry?.rider?.id === e.dataItem.riderId) {
          // This is the rider we want to update
          const updatedClassList =
            riderEntry.classList?.map(classItem => {
              if (classItem.class === className) {
                // Update the existing class entry
                return {
                  ...classItem,
                  splitNumber: event.name,
                };
              }
              return classItem;
            }) || [];

          // If the class doesn't exist, add it
          if (!updatedClassList.some(item => item.class === className)) {
            updatedClassList.push({
              class: className,
              splitNumber: event.name,
            });
          }

          return {
            ...riderEntry,
            classList: updatedClassList,
          };
        }
        return riderEntry;
      });
    });
  };
  const onClassBSplitNumberDataChange = (e, event, className) => {
    setRiderClass(prevState => {
      const currentState = prevState || [];
      return currentState.map(riderEntry => {
        if (riderEntry?.rider?.id === e.dataItem.riderId) {
          // This is the rider we want to update
          const updatedClassList =
            riderEntry.classList?.map(classItem => {
              if (classItem.class === className) {
                // Update the existing class entry
                return {
                  ...classItem,
                  splitNumber: event.name,
                };
              }
              return classItem;
            }) || [];

          // If the class doesn't exist, add it
          if (!updatedClassList.some(item => item.class === className)) {
            updatedClassList.push({
              class: className,
              splitNumber: event.name,
            });
          }

          return {
            ...riderEntry,
            classList: updatedClassList,
          };
        }
        return riderEntry;
      });
    });
  };
  const onClassAChange = (e, event, className) => {
    setRiderClass(prevState => {
      const currentState = prevState || [];
      return currentState.map(riderEntry => {
        if (riderEntry?.rider?.id === e.dataItem.riderId) {
          // This is the rider we want to update
          const updatedClassList =
            riderEntry.classList?.map(classItem => {
              if (classItem.class === className) {
                // Update the existing class entry
                return {
                  ...classItem,
                  splitNumber: event.name,
                };
              }
              return classItem;
            }) || [];

          // If the class doesn't exist, add it
          if (!updatedClassList.some(item => item.class === className)) {
            updatedClassList.push({
              class: className,
              splitNumber: event.name,
            });
          }

          return {
            ...riderEntry,
            classList: updatedClassList,
          };
        }
        return riderEntry;
      });
    });
  };
  const onClassBChange = (e, event, className) => {
    setRiderClass(prevState => {
      const currentState = prevState || [];
      return currentState.map(riderEntry => {
        if (riderEntry?.rider?.id === e.dataItem.riderId) {
          // This is the rider we want to update
          const updatedClassList =
            riderEntry.classList?.map(classItem => {
              if (classItem.class === className) {
                // Update the existing class entry
                return {
                  ...classItem,
                  splitNumber: event.name,
                };
              }
              return classItem;
            }) || [];

          // If the class doesn't exist, add it
          if (!updatedClassList.some(item => item.class === className)) {
            updatedClassList.push({
              class: className,
              splitNumber: event.name,
            });
          }

          return {
            ...riderEntry,
            classList: updatedClassList,
          };
        }
        return riderEntry;
      });
    });
  };
  const RiderColumn = [
    // {
    //   field: '',
    //   title: 'Rider Name',
    //   cell: e => <RiderNameClassCell e={e} />,
    // },
    {
      field: 'backNumber',
      title: 'Back Number',
      width: '100',
    },
    {
      field: 'height',
      title: 'Height',
      width: '100',
    },
    {
      field: 'weight',
      title: 'Weight',
      width: '100',
    },
    {
      field: 'grade.name',
      title: 'Grade',
      width: '100',
    },
    {
      field: 'state.name',
      title: 'State',
      width: '150',
    },
    {
      field: '',
      title: 'Class',
      cell: e => (
        <RiderClassUpdateCell
          e={e}
          classASplitNumber={classASplitNumber}
          setClassASplitNumber={setClassASplitNumber}
          classBSplitNumber={classBSplitNumber}
          setClassBSplitNumber={setClassBSplitNumber}
          onClassASplitNumberDataChange={onClassASplitNumberDataChange}
          onClassBSplitNumberDataChange={onClassBSplitNumberDataChange}
          onClassAChange={onClassAChange}
          classAChecked={classAChecked}
          setClassAChecked={setClassAChecked}
          classBChecked={classBChecked}
          setClassBChecked={setClassBChecked}
          onClassBChange={onClassBChange}
        />
      ),
      width: '400',
    },
  ];

  return (
    <>
      <StyledCommonPageWrapper>
        {loading && <CommonLoader loaderType="main" />}
        <StyledHeadingWrapper>
          <PageMainLabel>Add Rider</PageMainLabel>
          <CustomBreadCrumb preData={breadCrumpitemsUser} />
        </StyledHeadingWrapper>
        <ContentWrapper>
          <BtnWrapper>
            <div>
              <BackButton isNavHistory />
            </div>
            <div className="btn-action">
              <AdminCustomButton
                buttonType="cancel"
                onClick={handleCancelClick}
              >
                Cancel
              </AdminCustomButton>
              <AdminCustomButton buttonType="save" onClick={handleSaveClick}>
                Save
              </AdminCustomButton>
            </div>
          </BtnWrapper>
          <GridWrapper>
            <CustomgridLayoutWrapper
              className="custom-grid-wrapper"
              rows={[{ height: 'auto' }]}
              cols={[
                { width: 'calc(33.33% - 7px)' },
                { width: 'calc(33.33% - 7px)' },
                { width: 'calc(33.33% - 7px)' },
              ]}
              gap={{ rows: 20, cols: 10 }}
              style={{ paddingTop: '20px' }}
            >
              <GridLayoutItem className="team-style">
                <AdminDropDown
                  label="Team "
                  placeholder=""
                  data={getLookupArray('ACTIVETEAM', lookUps)}
                  onDataChange={onDataChangeTeam}
                  defaultValue={showsRiderData?.team ?? ''}
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
                {isCategoryNotInTeam && (
                  <span className="error-msg">
                    Selected team is not available, choose another team
                  </span>
                )}
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminDropDown
                  label="Category "
                  placeholder=""
                  data={categoryListFromTeam}
                  onDataChange={onDataChangeCategory}
                  defaultValue={showsRiderData?.category}
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  disabled={isEmptyArray(categoryListFromTeam)}
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminDropDown
                  label="Division"
                  placeholder=""
                  data={getLookupArray('DIVISION', lookUps)}
                  onDataChange={onDataChangeDivisionOne}
                  defaultValue={showsRiderData?.divisionClassOne! ?? undefined}
                  className="field-style"
                  disabled={isEmptyArray(divisionOneList)}
                  validations="required"
                  validator={simpleValidator.current}
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem className="multichecker-div">
                <AdminMultiSelectDropDown
                  label="Select Rider"
                  placeholder=""
                  onRemove={handleDataRemove}
                  onSelect={onDataChangeRider}
                  data={riderList}
                  setPreDataList={setRiderListShows}
                  preDataList={riderListShows}
                  className={'multi-checker'}
                  validations="required"
                  validator={simpleValidator?.current}
                  isLoader={loading}
                  disabled={isEmptyArray(riderList ?? [])}
                  isCustomValidationError={
                    isResponseRider && isEmptyArray?.(riderDataList ?? [])
                      ? true
                      : false
                  }
                  customErrorText={'No rider found'}
                />
              </GridLayoutItem>
            </CustomgridLayoutWrapper>
          </GridWrapper>
        </ContentWrapper>
        {/* {riderListInfo &&
          riderListInfo.map((riderInfoItem, index) => (
            <StyledCustomgridLayoutWrapper
              key={index} // Ensure each mapped item has a unique key
              rows={[{ height: 'auto' }]}
              cols={[{ width: '45%' }]}
              gap={{ rows: 20, cols: 10 }}
              className="main-grid"
            >
              <GridLayoutItem>
                {loading ? (
                  <CardSkeletonLoader
                    isProfileImageLoader
                    isTextLoader
                    lineContent={3}
                    isHeaderLoader
                    headerWidth={300}
                  />
                ) : (
                  <ProfileImageUploadCard
                    className="ProfileImageUploadCard"
                    imgSrc={riderInfoItem?.imageUrl}
                  >
                    <GridLayout
                      gap={{ rows: 0, cols: 10 }}
                      rows={[{ height: 'auto' }, { height: 'auto' }]}
                      cols={[
                        { width: '30%' },
                        { width: '10%' },
                        { width: '60%' },
                      ]}
                      className="profilecard-grid"
                    >
                      <GridLayoutItem
                        row={1}
                        col={1}
                        colSpan={3}
                        className="first-item"
                      >
                        <CloseButtonWrapper>
                          <img
                            src={closeIcon}
                            alt="Close"
                            onClick={() =>
                              handleCloseClick(riderInfoItem?.riderId ?? '')
                            }
                          />
                        </CloseButtonWrapper>
                        <h3 className="header-title back-number">
                          {capitalizeFirstLetter(
                            riderInfoItem?.firstName +
                              ' ' +
                              riderInfoItem?.lastName,
                          )}
                          <span>({riderInfoItem?.backNumber} )</span>
                        </h3>
                      </GridLayoutItem>
                      <GridLayoutItem
                        row={2}
                        col={1}
                        colSpan={2}
                        className="hosted-style"
                      >
                        <CustomLabelMedium color="#475467">
                          Height :{' '}
                          <span color="#9F0404">{riderInfoItem?.height} </span>
                        </CustomLabelMedium>
                      </GridLayoutItem>
                      <GridLayoutItem row={3} col={1} colSpan={2}>
                        <CustomLabelMedium color="#475467">
                          Grade : <span>{riderInfoItem?.grade?.name}</span>
                        </CustomLabelMedium>
                      </GridLayoutItem>
                      <GridLayoutItem
                        row={4}
                        col={1}
                        colSpan={2}
                        className="location-style"
                      >
                        <CustomLabelMedium color="#475467">
                          State : <span>{riderInfoItem?.state?.name}</span>
                        </CustomLabelMedium>
                      </GridLayoutItem>
                      <GridLayoutItem
                        row={2}
                        col={3}
                        colSpan={2}
                        className="right-side"
                      >
                        <CustomLabelMedium color="#475467">
                          weight : <span>{riderInfoItem?.weight}</span>
                        </CustomLabelMedium>
                      </GridLayoutItem>
                    </GridLayout>
                  </ProfileImageUploadCard>
                )}
              </GridLayoutItem>
            </StyledCustomgridLayoutWrapper>
          ))} */}

        <CustomDataGrid
          data={riderListInfo}
          columns={RiderColumn}
          pageSize={10}
          totalCount={riderListInfo.length}
        />

        {/* commented for future use */}

        {/* <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[
            { width: 'calc(33.33% - 8px)' },
            { width: 'calc(33.33% - 6px)' },
            { width: 'calc(33.33% - 9px)' },
          ]}
          gap={{ rows: 20, cols: 10 }}
          style={{ paddingTop: '20px' }}
        >
          <GridLayoutItem>
            <AdminDropDown
              label="Select Class"
              placeholder=""
              // onDataChange={data => handleTeamChange(data, 'teamId')}
              data={getLookupArray('DIVISION_CLASS', lookUps)}
              defaultValue={teamDefault}
              className="field-style"
              validations="required"
              validator={simpleValidator.current}
              isLoader={loading}
            />
          </GridLayoutItem>
        </CustomgridLayoutWrapper> */}
      </StyledCommonPageWrapper>
    </>
  );
};
const StyledCommonPageWrapper = styled(CommonPageWrapper)`
  .SplitWrapper {
    width: 100% !important;
    .SHdropdown {
      width: fit-content;
      .k-dropdownlist {
        @media (min-width: 500px) and (max-width: 991px) {
          width: 150px;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          width: 150px;
        }
        @media (min-width: 1200px) and (max-width: 1365px) {
          width: 170px;
        }
      }
    }
  }
  .admin-checkbox-style {
    width: calc(50% - 35px);
    max-width: 275px;
  }
  .checkbox-style {
    label {
      display: inline-block !important;
      width: 100% !important;
    }
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  padding: 15px 0;
`;
const GridWrapper = styled.div`
  margin-bottom: 10px;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 15px 1px 15px 5px;
  border-bottom: 1px solid #eaecf0;
  .btn-action {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
const StyledCustomgridLayoutWrapper = styled(CustomgridLayoutWrapper)`
  margin-bottom: 20px;
  .header-title.back-number {
    margin-bottom: 0px;
  }
  @media (min-width: 1365px) and (max-width: 4000px) {
    &.k-grid-layout {
      grid-template-columns: 50% !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1364px) {
    &.k-grid-layout {
      grid-template-columns: 75% !important;
    }
  }
  @media (min-width: 400px) and (max-width: 991px) {
    &.k-grid-layout {
      grid-template-columns: 100% !important;
    }
  }
`;
const StyledHeadingWrapper = styled(HeadingWrapper)`
  .k-breadcrumb {
    .k-breadcrumb-last-item {
      .k-breadcrumb-link {
        padding: 0 1px 0 3px;
      }
    }
  }
`;
