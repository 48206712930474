import { CardWrapper } from 'admin/components/wrappers';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import React, { SetStateAction } from 'react';
import styled from 'styled-components';
import ShowRiderBackNumberCell from 'app/components/CustomDataGrid/Cells/ShowRiderBackNumberCell';
import ShowRiderStateCell from 'app/components/CustomDataGrid/Cells/ShowRiderStateCell';
import ShowRiderBMICell from 'app/components/CustomDataGrid/Cells/ShowRiderBMICell';
import ManageRiderClassSplitUpdateCell from 'app/components/CustomDataGrid/Cells/ManageRiderClassSplitUpdateCell';
import { GeneralLookup, GeneratedSplit } from 'services/openapi';
import SplitRiderCell from 'app/components/CustomDataGrid/Cells/SplitRiderCell';
import SplitDivisonCell from 'app/components/CustomDataGrid/Cells/SplitDivisionCell';
import SplitTeamCell from 'app/components/CustomDataGrid/Cells/SplitTeamCell';
type Props = {
  debounceShowSearch: string;
  classASplitNumber: { [key: string]: GeneralLookup };
  setClassASplitNumber: React.Dispatch<
    React.SetStateAction<{ [key: string]: GeneralLookup }>
  >;
  classBSplitNumber: { [key: string]: GeneralLookup };
  setClassBSplitNumber: React.Dispatch<
    React.SetStateAction<{ [key: string]: GeneralLookup }>
  >;
  data?: any[];
  setTransformedData: React.Dispatch<SetStateAction<Array<any>>>;
  itemIndex?: number;
  transformedData?: any[];
};
const ShowGenerateSplitTable = ({
  debounceShowSearch,
  data,
  setTransformedData,
  itemIndex,
  transformedData,
}: Props) => {
  const ShowRider = [
    {
      field: 'team',
      title: 'Team',
      cell: e => <SplitTeamCell e={e} />,
      width: '300',
      locked: true,
    },
    {
      field: 'rider',
      title: 'Rider',
      cell: e => <SplitRiderCell e={e} />,
      width: '300',
    },

    {
      field: 'bmi',
      title: 'Height & Weight',
      cell: e => <ShowRiderBMICell e={e} />,
      width: '150',
    },
    {
      field: 'backNumber',
      title: 'Back',
      cell: e => <ShowRiderBackNumberCell e={e} />,
      width: '150',
    },
    {
      field: 'state',
      title: 'State',
      cell: e => <ShowRiderStateCell e={e} />,
      width: '200',
    },
    {
      field: 'divison',
      title: 'Divison',
      cell: e => <SplitDivisonCell e={e} />,
      width: '200',
    },
    {
      field: 'class',
      title: 'Class',
      cell: e => (
        <ManageRiderClassSplitUpdateCell
          e={e}
          isDisabled={true}
          onSaveClick={() => {}}
          setTransformedData={setTransformedData}
          itemIndex={itemIndex}
          transformedData={transformedData}
        />
      ),
      width: '450',
    },
  ];
  return (
    <>
      <CardWrapper>
        <GridWrapper>
          <CustomDataGrid
            showPagination={false}
            data={data}
            columns={ShowRider}
          />
        </GridWrapper>
      </CardWrapper>
    </>
  );
};

export default ShowGenerateSplitTable;
const GridWrapper = styled.div`
  .k-grid-container .k-grid-content {
    height: unset !important;
    min-height: 0px !important;
  }
  .k-grid-content tr {
    &:hover {
      td {
        background: #f0f0f0 !important;
      }
    }
  }
  .k-grid-content tr td {
    cursor: pointer;
  }
  .k-grid-content tr td,
  .checkbox-style label {
    cursor: not-allowed !important;
    input {
      cursor: unset !important;
    }
  }
`;
