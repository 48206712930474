import { BackNextButton } from 'app/components/BackNextButton';
import React from 'react';
import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import { useState } from 'react';
import SHdropdown from 'app/components/SHdropdown';
import {
  ApiError,
  GeneralLookup,
  GeneralLookupWrapper,
  LookupService,
  RegisterRider,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  onClickBackSchool?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNxtSchool?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  tryItRegistration: RegisterRider;
  setTryItRegistration: React.Dispatch<React.SetStateAction<RegisterRider>>;
  stateData?: GeneralLookup[];
};
const NewMemberSchoolDetails = ({
  onClickNxtSchool,
  onClickBackSchool,
  tryItRegistration,
  setTryItRegistration,
  stateData,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();

  const [valid, setValid] = useState<boolean>(false);
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setTryItRegistration?.({
        ...tryItRegistration,
        school: {
          ...tryItRegistration.school,
          zip: inputValue,
        },
      });
    }
  };
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [tryItRegistration]);
  return (
    <MainWrapper className="form-MainWrapper">
      <OverflowWrapper className="OverflowWrapper">
        <TextFieldGrid className="TextFieldGrid">
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="School Name"
              type="text"
              name="firstName"
              onChange={e => {
                setTryItRegistration({
                  ...tryItRegistration,
                  school: {
                    ...tryItRegistration.school,
                    schoolName: e.target.value!,
                  },
                });
              }}
              value={tryItRegistration?.school.schoolName!}
              validations={'required|max:60'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <HomeAddressWrapper className="horizontal-line">
          <p className="home-address">School Address</p>
        </HomeAddressWrapper>
        <TextFieldGrid>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Street Address"
              type="text"
              name="streetAddress"
              onChange={e => {
                setTryItRegistration({
                  ...tryItRegistration,
                  school: {
                    ...tryItRegistration.school,
                    street: e.target.value!,
                  },
                });
              }}
              value={tryItRegistration?.school.street!}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="City"
              type="text"
              name="city"
              onChange={e => {
                setTryItRegistration({
                  ...tryItRegistration,
                  school: {
                    ...tryItRegistration.school,
                    city: e.target.value!,
                  },
                });
              }}
              value={tryItRegistration?.school.city!}
              validations={'required|max:40'}
              validator={simpleValidator.current}
              maxLength={40}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="State"
              data={stateData}
              onDataChange={e => {
                setTryItRegistration({
                  ...tryItRegistration,
                  school: {
                    ...tryItRegistration.school,
                    state: e,
                  },
                });
              }}
              defaultValue={tryItRegistration?.school.state!}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Zip"
              type="text"
              name="zip"
              onChange={handleZipChange}
              value={tryItRegistration?.school.zip!}
              validations={'required|alpha_num|min:5|max:9'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickNxtSchool}
            onClickBack={onClickBackSchool}
            next="Next"
            back="Back"
            disableNext={valid}
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 230px);
  padding: 20px 0px 0px;
  position: relative;

  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: 100%;
  padding-bottom: 100px;
  padding: 20px 60px 100px 60px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
const HomeAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 12px;
  label {
    color: #e98d04;
    font-style: oblique;
  }

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
    color: #4f4f4f;
  }
`;
const TextFieldWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
`;
const StyledTextField = styled(TextField)`
  /* flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important; */
`;

const DropdownWrapper = styled.div`
  flex: 0 0 calc(50% - 20px);
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .drop-down {
    margin: 0px;
    width: 100% !important;
    height: 35px;
    color: #9597a6;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -22px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
    z-index: 2;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    height: 45px;
    bottom: -3px !important;
  }
  @media (min-width: 576px) and (max-width: 944px) {
    height: 45px;
    &.bg-color-change {
      bottom: -3px;
    }
  }
  @media (min-width: 945px) and (max-width: 1199px) {
    height: 50px;
    &.bg-color-change {
      bottom: -6px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1390px) {
    height: 55px;
    &.bg-color-change {
      bottom: -6px;
    }
  }
  @media (min-width: 1391px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default NewMemberSchoolDetails;
