import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { InfoLabel } from 'app/components/SHLabel';
import SHdropdown from 'app/components/SHdropdown';
import { TextField } from 'app/components/TextField';
import { BackNextButton } from 'app/components/BackNextButton';
import { CoachRegistration, GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import { digitRemovedValue } from 'utils/common';

type Props = {
  formNumber?: number;
  setFormNumber?: React.Dispatch<React.SetStateAction<number>>;
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  coachRegisterPayload: CoachRegistration;
  setCoachRegisterPayload: React.Dispatch<
    React.SetStateAction<CoachRegistration>
  >;
  teamData?: GeneralLookup[];
  stateData?: GeneralLookup[];
};

export const PersonalInfoForm = ({
  formNumber,
  setFormNumber,
  handleNextForm,
  handleBackForm,
  coachRegisterPayload,
  setCoachRegisterPayload,
  teamData,
  stateData,
}: Props) => {
  const [valid, setValid] = useState<boolean>(false);
  //Simple validator Ref
  const simpleValidator = React.useRef(new SimpleReactValidator());
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(formValid);
  }, [coachRegisterPayload]);

  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setCoachRegisterPayload({
        ...coachRegisterPayload,
        zip: inputValue,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setCoachRegisterPayload({
        ...coachRegisterPayload,
        primaryPhone: inputValue,
      });
    }
  };
  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setCoachRegisterPayload({
        ...coachRegisterPayload,
        emergencyContactPhone: inputValue,
      });
    }
  };
  const handleTeamChange = data => {
    if (coachRegisterPayload) {
      setCoachRegisterPayload({
        ...coachRegisterPayload,
        team: data,
      });
    }
  };
  return (
    <MainWrapper id="PersonalInfoFormMainWrapper">
      {' '}
      <p className="Team-Heading">New Member</p>
      <SubMainWrapper className="SubMainWrapper">
        <BorderWrapper className="inner-BorderWrapper">
          <InfoLabelWrapper id="InfoLabelWrapper">
            <div>
              <InfoLabel>
                Payment of $20 fee and proof of insurance must be accompanied
                with this form
              </InfoLabel>
            </div>
          </InfoLabelWrapper>
          <TextFieldGrid>
            <TextFieldWrapper>
              <StyledTextField
                onChange={e =>
                  setCoachRegisterPayload({
                    ...coachRegisterPayload,
                    firstName: e.target.value,
                  })
                }
                value={coachRegisterPayload?.firstName}
                label="First Name"
                type="text"
                name="firstName"
                validations={'required|max:30'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={e =>
                  setCoachRegisterPayload({
                    ...coachRegisterPayload!,
                    lastName: e?.target?.value!,
                  })
                }
                value={coachRegisterPayload?.lastName}
                label="Last Name"
                type="text"
                name="lastName"
                validations={'required|max:30'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <SHdropdown
                onDataChange={handleTeamChange}
                data={teamData}
                label="Team"
                defaultValue={coachRegisterPayload.team}
                validations={'required'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
          </TextFieldGrid>
          <MainAddressWrapper>
            <div className="horizontal-line">
              <span>Coach Address</span>
            </div>
            <TextFieldGrid>
              <TextFieldWrapper>
                <StyledTextField
                  onChange={e =>
                    setCoachRegisterPayload({
                      ...coachRegisterPayload,
                      street: e.target.value,
                    })
                  }
                  value={coachRegisterPayload?.street}
                  label=" Address"
                  type="text"
                  name="Address"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                <StyledTextField
                  onChange={e =>
                    setCoachRegisterPayload({
                      ...coachRegisterPayload,
                      city: e.target.value,
                    })
                  }
                  value={coachRegisterPayload?.city}
                  label=" City"
                  type="text"
                  name=" City"
                  validations={'required|max:40'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                <SHdropdown
                  defaultValue={coachRegisterPayload.state}
                  data={stateData}
                  onDataChange={data => {
                    setCoachRegisterPayload({
                      ...coachRegisterPayload,
                      state: data,
                    });
                  }}
                  label="State"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                <StyledTextField
                  onChange={handleZipChange}
                  value={coachRegisterPayload?.zip.replaceAll(/[^\w\d]/g, '')}
                  label="Zip"
                  type="text"
                  name="Zip"
                  validations={'required|alpha_num|min:5|max:9'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                <StyledTextField
                  onChange={handlePhChange}
                  value={coachRegisterPayload?.primaryPhone.replaceAll(
                    /[^\w\d]/g,
                    '',
                  )}
                  label="Phone Number:"
                  type="tel"
                  name="phoneNumber"
                  validations={'required|numeric|min:10|max:10'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                <StyledTextField
                  onChange={e =>
                    setCoachRegisterPayload({
                      ...coachRegisterPayload,
                      email: e.target.value,
                    })
                  }
                  value={coachRegisterPayload?.email}
                  label="Email:"
                  type="email"
                  name="email"
                  validations={'required|email'}
                  validator={simpleValidator.current}
                  maxLength={60}
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                <StyledTextField
                  onChange={e =>
                    setCoachRegisterPayload({
                      ...coachRegisterPayload,
                      emergencyContactName: digitRemovedValue(e.target.value),
                    })
                  }
                  value={coachRegisterPayload?.emergencyContactName}
                  label="Emergency Contact Name"
                  type="text"
                  name="Emergency Contact Name"
                  validations={'required|max:60'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                <StyledTextField
                  onChange={handleEmergencyPhChange}
                  value={coachRegisterPayload?.emergencyContactPhone.replaceAll(
                    /[^\w\d]/g,
                    '',
                  )}
                  label="PH:"
                  type="tel"
                  name="Ph"
                  validations={'required|numeric||min:10|max:10'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
            </TextFieldGrid>
          </MainAddressWrapper>
        </BorderWrapper>
      </SubMainWrapper>
      <BottomRightWrapper id="BottomRightWrapper">
        <ButtonWrapper>
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleNextForm}
            onClickBack={handleBackForm}
            next="Next"
            back="Cancel"
            disableNext={!valid}
          />
        </ButtonWrapper>
      </BottomRightWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 176px);
  position: relative;
  .Team-Heading {
    margin: 0px;
    padding: 0px !important;
    text-align: center;
    color: #9597a6;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 100px) and (max-width: 768px) {
    padding-top: 40px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const SubMainWrapper = styled.div`
  height: calc(100vh - 230px);
  width: 100%;
`;
const BorderWrapper = styled.div`
  height: 100%;
  overflow: auto;
  border-radius: 20px;
  padding: 20px 60px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 0 20px 10px 12px;
  text-align: start;
  width: 100%;
  div {
    border-bottom: 1px solid #434343;
    padding: 6px 0;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 35px 0;
`;

const TextFieldWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }

  @media (min-width: 100px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;

const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;

const MainAddressWrapper = styled.div`
  margin-bottom: 40px;
  padding: 0px 0 100px 0;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 12px 15px 12px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 0;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    bottom: -19px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    bottom: -19px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div`
  button {
    width: 100px;
    height: 30px;
    margin: 0px 10px;
    border-radius: 6px;
  }
  .btn-back {
    background-color: #2a2a2a;
    border: 1px solid #606060;
    color: #909090;
  }
  .btn-nxt {
    background-color: #306fdb;
    border: 1px solid #2a2a2a;
    color: #ffffff;
  }
  .btn-rgstr {
    background-color: #306fdb;
    border: 1px solid #2a2a2a;
    color: #ffffff;
  }
`;
