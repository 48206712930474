import { GridLayoutItem } from '@progress/kendo-react-layout';
import { CustomgridLayoutWrapper } from 'admin/components/wrappers';
import AdminDropDown from 'app/components/AdminDropDown';
import { AdminTextField } from 'app/components/AdminTextField';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import styled from 'styled-components';
import { MutableRefObject, useEffect, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import {
  GeneralLookup,
  GeneralLookupWrapper,
  ShowRegistration,
} from 'services/openapi';
import { getLookupArray, isoString, seasonValue } from 'utils/common';
import { DropDownModel } from 'app/components/SHdropdown';
import { AdminCheckBox } from 'app/components/AdminCheckbox';
import { AdminDatePicker } from 'app/components/AdminDatePicker';
import { FormSkeletonLoader } from 'app/components/FormSkeletonLoader';
import { isBefore, format } from 'date-fns';

type Props = {
  action?: string | undefined;
  simpleValidator: MutableRefObject<SimpleReactValidator>;
  showPayload: ShowRegistration;
  setShowPayload: React.Dispatch<React.SetStateAction<ShowRegistration>>;
  setImgSrc: React.Dispatch<React.SetStateAction<string | null>>;
  imgSrc: string | null;
  setImageFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
  showDataLoading: boolean;
  lookUps: GeneralLookupWrapper[];
  getAllLookupData: (names?: string | undefined) => void;
  cancelTrigger?: boolean;
};

export const ShowInfoFormBlock = ({
  action,
  simpleValidator,
  showPayload,
  setShowPayload,
  setImgSrc,
  imgSrc,
  setImageFile,
  showDataLoading,
  lookUps,
  getAllLookupData,
  cancelTrigger,
}: Props) => {
  const splitSeason =
    showPayload?.season?.name && showPayload?.season?.name.trim()
      ? showPayload?.season?.name?.split(' ')
      : seasonValue(lookUps)?.name
      ? seasonValue(lookUps).name.split(' ')
      : [];
  const years = splitSeason[1] ? splitSeason[1].split('-') : [];
  const yearStart = new Date(parseInt(years?.[0]), 0, 1);
  const yearEnd = new Date(parseInt(years?.[1]), 11, 31);

  const handleChange = event => {
    const { name, value } = event.target;
    setShowPayload(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDropdownChange = (
    data: DropDownModel | GeneralLookup,
    name: string,
  ) => {
    setShowPayload(prevData => ({
      ...prevData,
      [name]: data,
    }));
  };
  const handleImageSelect = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageFile(file);
    }
  };
  const handleDateChange = e => {
    const newStartDate = new Date(e.value);
    const endDate = new Date(showPayload.showEndDate);
    if (newStartDate > endDate) {
      setShowPayload({
        ...showPayload,
        showStartDate: isoString(e.value),
        showEndDate: '',
      });
    } else {
      setShowPayload({
        ...showPayload,
        showStartDate: isoString(e.value),
      });
    }
  };
  useEffect(() => {
    getAllLookupData('STATE,SEASON,ACTIVESTATUS,NATIONALINVITE');
  }, []);

  return (
    <>
      <GridWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[
            { width: 'calc(32.33% - 14px)' },
            { width: 'calc(68.67% - 14px)' },
          ]}
          gap={{ rows: 20, cols: 30 }}
        >
          <GridLayoutItem>
            <ProfileImageUploadCard
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              onSelectFile={handleImageSelect}
              uploadButtonLabel={`${imgSrc ? 'Change Image' : 'Upload Image'}`}
              uploadButtonType="primary"
              isLoader={showDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <CustomgridLayoutWrapper
              rows={[{ height: 'auto' }]}
              cols={[
                { width: 'calc(50% - 14px)' },
                { width: 'calc(50% - 14px)' },
              ]}
              gap={{ rows: 20, cols: 10 }}
            >
              <GridLayoutItem>
                <GridLayoutItem>
                  <AdminTextField
                    label="Show Name"
                    placeholder=""
                    value={showPayload.showName!}
                    onChange={handleChange}
                    name="showName"
                    validations={'required|max:150'}
                    validator={simpleValidator.current}
                    className="field-style"
                    isLoader={showDataLoading}
                  />
                </GridLayoutItem>
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminDropDown
                  label="Show Season"
                  placeholder=""
                  defaultValue={
                    action === 'edit'
                      ? showPayload.season
                      : seasonValue(lookUps)
                  }
                  className="field-style"
                  validations="required"
                  validator={simpleValidator.current}
                  disabled
                  isLoader={showDataLoading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="Show Host"
                  placeholder=""
                  value={showPayload.hostedBy!}
                  onChange={handleChange}
                  name="hostedBy"
                  validations={'required|max:150'}
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={showDataLoading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="Show Location"
                  placeholder=""
                  value={showPayload.showLocation!}
                  onChange={handleChange}
                  name="showLocation"
                  validations={'required|max:150'}
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={showDataLoading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminDatePicker
                  title="Start Date "
                  placeholder="day/month/year"
                  value={
                    showPayload?.showStartDate
                      ? new Date(showPayload.showStartDate)
                      : undefined
                  }
                  onChange={handleDateChange}
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style create-date"
                  mindate={yearStart}
                  maxdate={yearEnd}
                  isLoader={showDataLoading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminDatePicker
                  disabled={
                    !showPayload.showStartDate ||
                    showPayload.showStartDate === undefined
                  }
                  title="End Date"
                  placeholder="day/month/year"
                  value={
                    showPayload?.showEndDate
                      ? new Date(showPayload.showEndDate)
                      : undefined
                  }
                  onChange={e => {
                    if (e.value && showPayload.showStartDate) {
                      const newEndDate = e.value;
                      const currentStartDate = new Date(
                        showPayload.showStartDate,
                      );
                      if (isBefore(newEndDate, currentStartDate)) {
                        setShowPayload({
                          ...showPayload,
                          showEndDate: format(
                            currentStartDate,
                            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
                          ),
                        });
                      } else {
                        setShowPayload({
                          ...showPayload,
                          showEndDate: format(
                            newEndDate,
                            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
                          ),
                        });
                      }
                    }
                  }}
                  validations="required"
                  validator={simpleValidator.current}
                  className={`${
                    !showPayload?.showStartDate && 'end-date'
                  } end-date-picker field-style`}
                  mindate={new Date(showPayload?.showStartDate)}
                  maxdate={yearEnd}
                  isLoader={showDataLoading}
                />
              </GridLayoutItem>
              {showDataLoading ? (
                <FormSkeletonLoader lineContent={1} textWidth={100} />
              ) : (
                <GridLayoutItem>
                  <div className="checkbox">
                    <AdminCheckBox
                      value={showPayload.isNationalFinal}
                      onChange={e => {
                        setShowPayload({
                          ...showPayload,
                          isNationalFinal: e.value,
                        });
                      }}
                      label="Is this National Final"
                      className="checkbox-style"
                    />
                  </div>
                </GridLayoutItem>
              )}
            </CustomgridLayoutWrapper>
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[
            { width: 'calc(33.33% - 8px)' },
            { width: 'calc(33.33% - 6px)' },
            { width: 'calc(33.33% - 9px)' },
          ]}
          gap={{ rows: 20, cols: 10 }}
          style={{ paddingTop: '20px' }}
        >
          <GridLayoutItem>
            <AdminDropDown
              label="Invitational/Non Pointed Show"
              placeholder=""
              data={getLookupArray('NATIONALINVITE', lookUps)}
              onDataChange={data => handleDropdownChange(data, 'natInvite')}
              defaultValue={showPayload.natInvite}
              className="field-style"
              validations={'required|max:150'}
              validator={simpleValidator.current}
              isLoader={showDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Address"
              placeholder=""
              value={showPayload.address!}
              onChange={handleChange}
              name="address"
              validations={'required|max:150'}
              validator={simpleValidator.current}
              className="field-style"
              isLoader={showDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="City"
              placeholder=""
              value={showPayload.city!}
              onChange={handleChange}
              name="city"
              validations={'required|max:150'}
              validator={simpleValidator.current}
              className="field-style"
              isLoader={showDataLoading}
            />
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[
            { width: 'calc(16.61% - 8px)' },
            { width: 'calc(16.61% - 8px)' },
            { width: 'calc(33.33% - 6px)' },
            { width: 'calc(33.33% - 6px)' },
          ]}
          gap={{ rows: 20, cols: 10 }}
          className="grid-layout-sub common-style second-grid"
          style={{ paddingTop: '10px' }}
        >
          <GridLayoutItem>
            <AdminDropDown
              label="State"
              placeholder=""
              data={getLookupArray('STATE', lookUps)}
              onDataChange={data => handleDropdownChange(data, 'state')}
              defaultValue={showPayload?.state}
              className="field-style"
              validations="required"
              validator={simpleValidator.current}
              isLoader={showDataLoading}
            />
          </GridLayoutItem>

          <GridLayoutItem>
            <AdminTextField
              label="Zip"
              placeholder=""
              value={showPayload?.zip!}
              onChange={handleChange}
              name="zip"
              validations={'required|max:150'}
              validator={simpleValidator.current!}
              className="field-style"
              isLoader={showDataLoading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              label="Status"
              placeholder=""
              data={getLookupArray('ACTIVESTATUS', lookUps)}
              onDataChange={data => handleDropdownChange(data, 'status')}
              defaultValue={showPayload.status}
              className="field-style"
              validations="required"
              validator={simpleValidator.current}
              isLoader={showDataLoading}
            />
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
      </GridWrapper>
    </>
  );
};

const GridWrapper = styled.div`
  padding: 10px 0 20px;
  .end-date {
    :has(input:disabled) {
      .red-star {
        display: unset !important;
      }
    }
    pointer-events: none;
    border: unset !important;
    .k-button-icon {
      display: unset !important;
      opacity: 0.3;
    }
  }
  .end-date-picker {
    .disabled {
      border: 1px solid #d0d5dd !important;
      border-color: #d0d5dd !important;
      background-color: #f5f5f5 !important;
      .k-button-solid-base {
        background-color: #f5f5f5;
      }
    }
  }
  .k-grid-layout {
    .checkbox {
      margin-left: 0px;
      .checkbox-style {
        margin: 6px 0px 1px 0px !important;
        .k-checkbox-wrap {
          padding-bottom: 5px;
        }
      }

      input[type='checkbox'] {
        border: 1px solid #a5a5a5;
      }
      label {
        color: #475467;
        font-size: 15px;
        line-height: 18.15px;
      }
      @media (min-width: 100px) and (max-width: 1365px) {
        margin-left: 5px;
        margin-right: unset;
      }
      @media (min-width: 100px) and (max-width: 319px) {
        label {
          font-size: 11px;
        }
      }
      @media (min-width: 320px) and (max-width: 575px) {
        label {
          font-size: 12px;
        }
      }
      @media (min-width: 576px) and (max-width: 767px) {
        label {
          font-size: 13px;
        }
      }
      @media (min-width: 576px) and (max-width: 767px) {
        label {
          font-size: 14px;
        }
      }
    }
  }
`;
