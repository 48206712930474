import React, { useState } from 'react';
import { CheckBox } from 'app/components/CheckBox';
import styled from 'styled-components';
import '@fontsource/roboto';
import { TextField } from 'app/components/TextField';
import SHdropdown, { DropDownModel } from 'app/components/SHdropdown';
import { useNavigate } from 'react-router-dom';
import { BackNextButton } from 'app/components/BackNextButton';

type Props = { labelName?: string };

const dummyData: DropDownModel[] = [
  { id: 1, text: 'Option 1' },
  { id: 2, text: 'Option 2' },
  { id: 3, text: 'Option 3' },
  { id: 4, text: 'Option 4' },
  { id: 5, text: 'Option 5' },
];

export const TryitEligibilityDetailsReturning = ({}: Props) => {
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState({
    firstName: '',
    lastName: '',
    teamName: '',
    email: '',
    emergencyContact: '',
    assistantEmail: '',
    mainContact: '',
    phoneNumber: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
  });
  const handleChange = event => {
    const { name, value } = event.target;
    console.log('changed', value);
    setDetailsData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleNxt = () => {
    navigate(
      '/tryit-ewd-membership-form/returning-member/eligibile-conditions',
    );
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <MainWrapper>
      <p className="first-para">
        A membership registration forms for Equestrian with Disabilities must be
        accompanied by a Diagnosis and Adaptive Equipment Form.
      </p>
      <OverflowWrapper>
        <TextFieldGrid>
          <StyledTextField
            onChange={handleChange}
            value={detailsData.firstName || ''}
            label="First Name"
            type="text"
            name="firstName"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.lastName || ''}
            label="Last Name"
            type="text"
            name="lastName"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.teamName || ''}
            label="Team Name"
            type="text"
            name="teamName"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.phoneNumber || ''}
            label="Phone Number"
            type="number"
            name="phoneNumber"
          />
        </TextFieldGrid>
        <MainAddressWrapper>
          <div className="horizontal-line">
            <span>Rider's Address</span>
          </div>
          <TextFieldGrid>
            <StyledTextField
              onChange={handleChange}
              value={detailsData.streetAddress || ''}
              label="Street Address"
              type="text"
              name="streetAddress"
            />
            <StyledTextField
              onChange={handleChange}
              value={detailsData.city || ''}
              label="City"
              type="text"
              name="city"
            />
            <DropdownWrapper>
              <SHdropdown className="drop-down" label="State" />
            </DropdownWrapper>
            <StyledTextField
              onChange={handleChange}
              value={detailsData.zip || ''}
              label="Zip"
              type="text"
              name="zip"
            />
            <StyledTextField
              onChange={handleChange}
              value={detailsData.emergencyContact || ''}
              label="Emergency Contact Name"
              type="number"
              name="emergencyContact"
            />
            <StyledTextField
              onChange={handleChange}
              value={detailsData.email || ''}
              label=" Email"
              type="email"
              name="email"
            />
          </TextFieldGrid>
        </MainAddressWrapper>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleNxt}
            onClickBack={handleBack}
            next="Next"
            back="Cancel"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 150px);
  overflow: auto;
  padding-top: 50px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 40px;
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding-top: 45px;
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding-top: 50px;
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  padding-bottom: 20px;

  @media (min-width: 992px) and (max-width: 1199px) {
    gap: 5px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    gap: 10px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    gap: 15px;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 20px !important;
`;
const MainAddressWrapper = styled.div`
  padding-bottom: 50px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 0 15px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const DropdownWrapper = styled.div`
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .drop-down {
    margin: 0px;
    width: 326px !important;
    height: 35px;
    color: #9597a6;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 1px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
